<template>
  <h1 class="mt-10">Passwort neu setzen</h1>
  <div v-if="form.success" class="col-8">
    <div class="alert alert-success" role="alert">
      {{ form.success }}
    </div>
  </div>
  <form v-else name="login" @submit.prevent="resetPassword">
    <div class="form-row">
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="email">E-Mail</label>
          </div>
          <input v-model="form.email" class="form-control form-control-lg" id="email" placeholder="" required>
        </div>
      </div>
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="password">Passwort</label>
          </div>
          <input
              v-model="form.password"
              @change="validatePassword"
              class="form-control form-control-lg" id="password" :type="passwordType"
                 placeholder="" required>
          <div @click="togglePassword" class="input-group-append">
            <span class="input-group-text">
              <svg v-if="passwordHidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16"><defs><clipPath id="b"><rect width="16" height="16"/></clipPath></defs><g id="a" clip-path="url(#b)"><g transform="translate(-4 -6.5)"><path d="M12,18.5c-3.866,0-7-4-7-4s3.134-4,7-4,7,4,7,4S15.866,18.5,12,18.5Z" fill="none" stroke="#3b3b3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75"/><path d="M12,17a2.5,2.5,0,1,0-2.5-2.5A2.5,2.5,0,0,0,12,17Z" fill="none" stroke="#3b3b3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75"/></g></g></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16"><defs><clipPath id="b"><rect width="16" height="16"/></clipPath></defs><g id="a" clip-path="url(#b)"><g transform="translate(-4 -6.5)"><path d="M12,18.5c-3.866,0-7-4-7-4s3.134-4,7-4,7,4,7,4S15.866,18.5,12,18.5Z" fill="none" stroke="#3b3b3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75"/><path d="M12,17a2.5,2.5,0,1,0-2.5-2.5A2.5,2.5,0,0,0,12,17Z" fill="none" stroke="#3b3b3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75"/></g><path d="M0,10.768,6.31,4.711,11.217,0" transform="translate(2.392 2.616)" fill="none" stroke="#3b3b3b" stroke-linecap="round" stroke-width="0.75"/></g></svg>
            </span>
          </div>
        </div>
      </div>
      <div v-if="form.err" class="col-8">
        <div class="alert alert-danger" role="alert">
          {{ form.err }}
        </div>
      </div>
      <div class="col-8">
        <button type="submit" class="mb-4">Speichern</button>
        <input v-model="form.hash" id="hash" type="hidden"/>
      </div>
    </div>
  </form>
  <div class="col-8 text-center">
    <router-link :to="{name: 'SignIn'}">Zum Login</router-link>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      form: {
        email: '',
        password: '',
        err: '',
        hash: '',
        success: ''
      },
      passwordType: 'password',
      passwordHidden: true,
      passwordValid: false
    }
  },
  props: {
    hash: {
      required: true,
      type: String
    }
  },
  created() {
    this.form.hash = this.hash
  },
  methods: {
    togglePassword() {
      this.passwordHidden = !this.passwordHidden
      this.passwordType = this.passwordHidden ? 'password' : 'text'
    },
    validatePassword(event) {
      const password = event.target.value

      this.passwordValid = password.length >= 12 &&
          /[0-9]/.test(password) &&
          /[A-Z]/.test(password) &&
          /[a-z]/.test(password) &&
          // eslint-disable-next-line
          /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);
    },
    async resetPassword() {
      if (!this.passwordValid)
        this.form.err = 'Das Passwort benötigt mindestens 12 Zeichen, Groß- und Kleinschreibung, Zahlen und ein Sonderzeichen.'
      else {
        this.form.err = null
        const response = await this.$store.dispatch('resetPassword', {...this.form})

        if (response.success) this.form.success = response.success
        else if (response.err) this.form.err = response.err

        this.form.resetPassword = '0'
      }
    }
  }
}
</script>

<style scoped>
.input-group-append {
  cursor: pointer;
}
</style>