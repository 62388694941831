<template>
  <div
      v-if="workflowState === 0"
      class="w-100">

    <form v-if="showWashingTime || showBruttoTara" v-show="canWrite" @submit.prevent="saveWashed">

      <div v-if="showBruttoTara" class="row">
        <h2 class="mb-3">Eingang</h2>
        <div class="input-group col-8 mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="incomingGrossWeight">Brutto-Gewicht</label>
          </div>
          <input v-model="incomingGrossWeight" type="number" step=".01" class="form-control form-control-lg"
                 id="incomingGrossWeight" placeholder="">
          <div class="input-group-append">
            <span class="input-group-text">kg</span>
          </div>
        </div>
        <div class="input-group col-8 mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="incomingTareWeight">Tara-Gewicht</label>
          </div>
          <input v-model="incomingTareWeight" type="number" step=".01" class="form-control form-control-lg"
                 id="incomingTareWeight" placeholder="">
          <div class="input-group-append">
            <span class="input-group-text">kg</span>
          </div>
        </div>
      </div>

      <div v-if="showWashingTime" class="row">
        <h2 class="mb-3" :class="showBruttoTara ? 'pt-1' : ''">Waschen</h2>
        <div class="col-4"><h6>Start</h6></div>
        <div class="input-group col-4 w55">
          <div class="input-group-prepend">
            <label class="input-group-text" for="washedStartDate">D</label>
          </div>
          <input v-model="washedStartDate" class="form-control form-control-lg"
                 id="washedStartDate" placeholder="TT.MM.JJJJ" pattern="\d{1,2}.\d{1,2}.\d{4}">
        </div>
        <div class="input-group col-4 w45">
          <div class="input-group-prepend">
            <label class="input-group-text" for="washedStartTime">Z</label>
          </div>
          <input v-model="washedStartTime" class="form-control form-control-lg"
                 id="washedStartTime" placeholder="HH:mm">
        </div>

        <div class="col-4 mt-2"><h6>Ende</h6></div>
        <div class="input-group col-4 w55">
          <div class="input-group-prepend">
            <label class="input-group-text" for="washedDate">D</label>
          </div>
          <input v-model="washedDate" class="form-control form-control-lg"
                 id="washedDate" placeholder="TT.MM.JJJJ" pattern="\d{1,2}.\d{1,2}.\d{4}">
        </div>
        <div class="input-group col-4 w45">
          <div class="input-group-prepend">
            <label class="input-group-text" for="washedTime">Z</label>
          </div>
          <input v-model="washedTime" class="form-control form-control-lg"
                 id="washedTime" placeholder="HH:mm">
        </div>
      </div>

      <div v-if="washingStored" class="col-8 mt-3 text-right">
        <img class="opened" src="@/assets/images/icon-done.svg"/>
        gespeichert
      </div>
      <button @click="storeWashing" class="mt-3 mb-3" type="button">Speichern</button>
    </form>

    <h2>Nassbehälter</h2>
    <div
        v-if="getWetVessels().length">
      <ul class="output">
        <li
            v-for="bag in getWetVessels()"
            :key="bag.vesselId">
          <label class="col-3">{{ bag.uniqueNumber }}</label> {{ parseNumber(bag.weight) }} kg
          <button v-show="canWrite" @click="openDialog(
              { element: 'der Nassbehälter',nbr: bag.uniqueNumber, weight: bag.weight, uid: bag.uniqueId})"
                  class="del">-
          </button>
        </li>
      </ul>
    </div>
    <form v-show="canWrite" @submit.prevent="saveBag"
          class="row">
      <div class="input-group col-6">
        <div class="input-group-prepend">
          <label class="input-group-text" for="wetWeight">Nass</label>
        </div>
        <input v-model="wetWeight" type="number" step="0.1" class="form-control form-control-lg"
               id="wetWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <button class="col-2 mt-0 mb-0 pt-0 pb-0" type="submit" style="text-align: center">+</button>
    </form>
    <div
        v-if="getWetVessels().length">
      <h2>Nassgewicht</h2>
      <ul class="output">
        <li class="bold">
          <label class="col-2 bold">Gesamt</label> {{ totalWeight(1) }} kg
        </li>
      </ul>
      <h2>Notizen</h2>
      <form class="row">
        <div class="form-group col-8">
          <label for="noteQuality">Qualität</label>
          <textarea v-model="noteQuality" class="form-control" id="noteQuality" rows="3"></textarea>
        </div>
        <div v-if="showBatchSampleNumber" class="input-group col-8 mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="sampleNumber">Probe Nr</label>
          </div>
          <input v-model="sampleNumber" class="form-control form-control-lg" id="sampleNumber" placeholder="">
        </div>
        <div class="col-1">
          <label for="wetWeight">Foto</label>
        </div>
        <div class="col-5">
          <input id="camera" ref="camera" @change="onFileChange" type="file"
                 style="display: none" accept="image/*" capture="camera"/>
          <img :src="noteImage" style="max-width: 100%;max-height: 300px"/>
        </div>
        <div v-show="canWrite" class="col-2">
          <button v-if="noteImage" @click="resetPhoto" class="del">-</button>
          <button v-else class="mt-0 mb-0 pt-0 pb-0" type="submit" @click="openCamera" style="text-align: center">+
          </button>
        </div>
        <!--        <input type="file" accept="image/*" capture="camera" id="customFile2"/>-->
      </form>
      <button v-show="canWrite" @click="finishWashing" type="button" class="done">Abschluss</button>
    </div>
  </div>
  <div
      v-else-if="workflowState === 1"
      class="w-100">

    <h2 class="mb-3">Trocknung</h2>
    <form v-show="canWrite" @submit.prevent="saveDried" class="row">
      <div v-if="dryersAmount > 1" class="input-group col-8 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryerId">Trockner</label>
        </div>
        <select v-if="dryers"
                v-model="dryerId"
                class="custom-select custom-select-lg" id="dryerId">
          <option
              v-for="(item,index) in dryers"
              :key="index"
              :value="index">{{ item }}</option>
        </select>
        <select v-else
            v-model="dryerId"
            class="custom-select custom-select-lg" id="dryerId"
        >
          <option
              v-for="index in dryersAmount"
              :key="index"
              :value="index">Trockner {{ index }}
          </option>
        </select>
      </div>
      <div class="input-group col-8 mb-3" v-if="showDryingTemperature">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryingTemperature">Temperatur</label>
        </div>
        <input  v-model="dryingTemperature" type="number" step="1" class="form-control form-control-lg"
               id="dryingTemperature" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">°</span>
        </div>
      </div>
      <div class="input-group col-8 mb-3" v-if="showDriedMoundHeight">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedMoundHeight">Aufschütthöhe</label>
        </div>
        <input  v-model="driedMoundHeight" type="number" step="1" class="form-control form-control-lg"
               id="driedMoundHeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">cm</span>
        </div>
      </div>
      <div v-if="showDryingStartTime || showDryingHeatMeter" class="col-4"><h6>Start</h6></div>
      <div v-if="showDryingStartTime" class="input-group col-4 w55">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedStartDate">D</label>
        </div>
        <input v-model="driedStartDate"  class="form-control form-control-lg"
               id="driedStartDate" placeholder="TT.MM.JJJJ" pattern="\d{1,2}.\d{1,2}.\d{4}">
      </div>
      <div v-if="showDryingStartTime" class="input-group col-4 w45">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedStartTime">Z</label>
        </div>
        <input v-model="driedStartTime" class="form-control form-control-lg"
               id="driedStartTime" placeholder="HH:mm">
      </div>
      <div v-if="showDryingStartMoisture" class="input-group col-8 mb-3 mt-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedStartMoisture">Feuchtigkeit</label>
        </div>
        <input v-model="driedStartMoisture" type="number" step=".01" class="form-control form-control-lg"
               id="driedStartMoisture" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
      <div class="input-group col-8 mb-2 mt-3" v-if="showDryingHeatMeter">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryingHeatMeterStart">Wärmezähler-Stand</label>
        </div>
        <input  v-model="dryingHeatMeterStart" type="number" step="1" class="form-control form-control-lg"
                id="dryingHeatMeterStart" placeholder="">
      </div>

      <div v-if="showDryingStartTime || showDryingHeatMeter" class="col-4 mt-2"><h6>Ende</h6></div>
      <div class="input-group col-4 w55">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedDate">D</label>
        </div>
        <input v-model="driedDate" class="form-control form-control-lg"
               id="driedDate" placeholder="TT.MM.JJJJ" pattern="\d{1,2}.\d{1,2}.\d{4}">
      </div>
      <div class="input-group col-4 w45">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedTime">Z</label>
        </div>
        <input v-model="driedTime" class="form-control form-control-lg"
               id="driedTime" placeholder="HH:mm">
      </div>
      
      <div class="input-group col-8 mb-3 mt-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedMoisture">Feuchtigkeit</label>
        </div>
        <input v-model="driedMoisture" type="number" step=".01" class="form-control form-control-lg"
               id="driedMoisture" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
      
      <div class="input-group col-8 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedHlWeight">HL-Gewicht</label>
        </div>
        <input v-model="driedHlWeight" type="number" step=".01" class="form-control form-control-lg"
               id="driedHlWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <div class="input-group col-8 mb-3" v-if="showDryingHeatMeter">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryingHeatMeterEnd">Wärmezähler-Stand</label>
        </div>
        <input  v-model="dryingHeatMeterEnd" type="number" step="1" class="form-control form-control-lg"
                id="dryingHeatMeterEnd" placeholder="">
      </div>
      <div class="input-group form-check mt-3">
        <input v-model="dryerCleaned" type="checkbox" class="form-check" id="dryerCleaned">
        <label class="form-check-label ml-2" for="dryerCleaned">Trockner gereinigt</label>
      </div>
      <div v-if="dryingStored" class="col-8 mt-3 text-right">
        <img class="opened" src="@/assets/images/icon-done.svg"/>
        gespeichert
      </div>
      <button @click="storeDrying" class="mt-3 mb-3" type="button">Speichern</button>
    </form>

    <h2>Big Bags</h2>
    <div
        v-if="getDryVessels().length">
      <ul class="output">
        <li
            v-for="bag in getDryVessels()"
            :key="bag.vesselId">
          <button v-show="canWrite" @click="openDialog(
              { element: 'der Big Bag',nbr: bag.uniqueNumber, weight: bag.weight, uid: bag.uniqueId})"
                  class="del">-
          </button>
          <label class="col-2">{{ bag.uniqueNumber }}</label> {{ parseNumber(bag.weight) }} kg
          <br v-if="bag.regraterId != batch.regraterId"/>
          <label v-if="bag.regraterId != batch.regraterId" class="offset-1">&uarr; {{ regraterName(bag.regraterId) }}</label>
          <br v-if="bag.stockLocation"/>
          <label v-if="bag.stockLocation">Lager {{ bag.stockLocation }}</label>
          <button v-show="canWrite" @click="editBag(bag)" class="edit">~</button>
          <api-pdf-link method="bigbagslip"
                        @openedPdf="openedPdf"
                        :elementId="bag.uniqueId" :opened="parseInt(bag.openedBigBagSlip)" class="float-right"/>
        </li>
      </ul>
    </div>
    <form v-show="canWrite" @submit.prevent="saveBag"
          class="row">
      <div class="input-group col-8 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="vessel_regraterId">Aufkäufer</label>
        </div>
        <select
            v-model="vessel_regraterId" @change="getFillableVessels"
            class="custom-select custom-select-lg" id="vessel_regraterId">
          <option value="">Auswählen..</option>
          <option
              v-for="regrater in regraters"
              :key="regrater.contactId"
              :value="regrater.contactId">{{ regrater.name }}
          </option>
        </select>
      </div>
      <div class="input-group col-8 mb-3" v-if="showStockLocation">
        <div class="input-group-prepend">
          <label class="input-group-text" for="stockLocation">Lagerort</label>
        </div>
        <input v-model="stockLocation" type="string" step=".01" class="form-control form-control-lg"
               id="stockLocation" placeholder="">
      </div>
      <div class="input-group col-6">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryWeight">Trocken</label>
        </div>
        <input v-model="dryWeight" type="number" step=".01" class="form-control form-control-lg"
               id="dryWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <button class="col-2 mt-0 mb-0 pt-0 pb-0" type="submit" style="text-align: center">+</button>
      <details class="col-8" :open="vessel_parentForm">
        <summary><h2>Big Bag auffüllen</h2></summary>
        <select
            v-model="parent_vesselId"
            class="custom-select custom-select-lg" id="parent_vesselId"
        >
          <option value="">Auswählen...</option>
          <option
              v-for="parent in fillableVessels"
              :key="parent.vesselId"
              :value="parent.uniqueId">{{ parent.uniqueNumber }} {{ parseNumber(parent.weight) }} kg
          </option>
        </select>
      </details>
    </form>
    <div
        v-if="getDryVessels().length">
      <h2>Trockengewicht</h2>
      <ul class="output">
        <li class="bold">
          <label class="col-4 bold">Gesamt</label> {{ totalWeight(2) }} kg
        </li>
      </ul>
      <button v-show="canWrite" @click="finishDrying" type="button" class="done">Abschluss</button>
    </div>
  </div>
  <div
      v-else-if="workflowState === 2 && getDryVessels().length" class="w-100">
    <h2>Big Bags</h2>
    <div
        v-if="getDryVessels().length">
      <ul class="output">
        <li
            v-for="bag in getDryVessels()"
            :key="bag.vesselId">
          <label class="col-3">{{ bag.uniqueNumber }}</label> {{ parseNumber(bag.weight) }} kg
          <label v-if="getChildVesselsText(bag.vesselId)">+ {{ getChildVesselsText(bag.vesselId) }}</label>
          <br v-if="bag.regraterId != batch.regraterId"/>
          <label v-if="bag.regraterId != batch.regraterId">&uarr; {{ regraterName(bag.regraterId) }}</label>
          <br v-if="bag.stockLocation"/>
          <label v-if="bag.stockLocation">Lager {{ bag.stockLocation }}</label>
          <api-pdf-link method="bigbagslip"
                        @openedPdf="openedPdf"
                        :elementId="bag.uniqueId" :opened="parseInt(bag.openedBigBagSlip)" class="float-right"/>
        </li>
      </ul>
    </div>
    <form @submit.prevent="saveBag"
          class="row">
    </form>
  </div>
  <app-modal-dialog v-if="modalOpened" @abortDialog="abortDialog" @confirmDialog="confirmDialog">
    <h1>Löschen bestätigen</h1>
    <div class="w-100">{{ modalText }}</div>
    <div class="w-100"><strong>{{ modalElement }}</strong></div>
  </app-modal-dialog>
  <popup-bag-editor v-if="bagEditorOpened" :element="bagElement" :regraters="regraters" :show-stock-location="showStockLocation"
                    @abortDialog="abortBagEdit" @confirmDialog="saveBagEdit"></popup-bag-editor>
  <!--  <div-->
  <!--      v-else-if="workflowState === 1"-->
  <!--      class="row">-->
  <!--    <h2>Big Bags</h2>-->
  <!--    <form @submit.prevent="save" class="col-8">-->
  <!--      &lt;!&ndash;      <input name="formName" value="dry" type="hidden"/>&ndash;&gt;-->
  <!--      <div class="input-group mb-3">-->
  <!--        <div class="input-group-prepend">-->
  <!--          <label class="input-group-text" for="weight">Gewicht</label>-->
  <!--        </div>-->
  <!--        <input v-model="weight" type="number" class="form-control form-control-lg"-->
  <!--               id="weight" placeholder="">-->
  <!--        <div class="input-group-append">-->
  <!--          <span class="input-group-text">kg</span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <button type="submit">Hinzufügen</button>-->
  <!--    </form>-->
  <!--  </div>-->
</template>

<script>
import ApiPdfLink from "@/components/ApiPdfLink";
import AppModalDialog from "@/components/AppModalDialog";
import dayjs from "dayjs";
import PopupBagEditor from "@/components/PopupBagEditor";
//import customParseFormat from "dayjs/plugin/customParseFormat";


export default {
  name: "BagList",
  components: {PopupBagEditor, AppModalDialog, ApiPdfLink},
  data() {
    return {
      activeBags: [...this.bags],
      weight: null,
      wetWeight: null,
      dryWeight: null,
      noteQuality: '',
      noteImage: '',
      sampleNumber: '',
      modalOpened: false,
      modalText: '',
      modalElement: '',
      modalUid: '',
      dryerId: 1,
      incomingGrossWeight: null,
      incomingTareWeight: null,
      washedStartDate: '',
      washedStartTime: '',
      washedDate: '',
      washedTime: '',
      washingStored: false,
      dryingTemperature: null,
      dryingHeatMeterStart: null,
      dryingHeatMeterEnd: null,
      driedStartTime: '',
      driedStartDate: '',
      driedStartMoisture: null,
      driedDate: '',
      driedTime: '',
      driedMoisture: null,
      driedHlWeight: null,
      dryerCleaned: true,
      dryingStored: false,
      bagElement: null,
      bagEditorOpened: false,
      vessel_regraterId: '',
      vessel_parentForm: false,
      fillableVessels: [],
      parent_vesselId: '',
      driedMoundHeight: '',
      stockLocation: '',
    }
  },
  props: {
    batch: {
      type: Object,
      required: true
    },
    bags: {
      type: Array,
      required: true
    },
    workflowState: {
      type: Number,
      required: false
    },
    canWrite: {
      type: Boolean,
      default: false
    },
    regraters: {
      type: Array,
      required: true
    },
    elementValue: {
      type: Boolean,
      required: false
    }
  },
  async created() {
    const batch = this.$props.batch

    switch (this.workflowState) {
      case 0:
        if (this.showWashingTime) {
          // console.log('----',batch.washedStartDateTime)
          this.setWashedStartDateTime(batch.washedStartDateTime)

          if (batch.washedDateTime) {
            this.setWashedDateTime(batch.washedDateTime)
          } else if (batch.washedStartDateTime)
            this.setWashedDateTime()
        }
        break;
      case 1:
        if (batch.dryerId)
          this.dryerId = batch.dryerId
        if (batch.dryingTemperature)
          this.dryingTemperature = batch.dryingTemperature
        if (batch.dryingHeatMeterStart)
          this.dryingHeatMeterStart = batch.dryingHeatMeterStart
        if (batch.dryingHeatMeterEnd)
          this.dryingHeatMeterEnd = batch.dryingHeatMeterEnd
        if (batch.driedStartDateTime) {
          this.setDriedStartDateTime(batch.driedStartDateTime)
        } else if (this.showDryingStartTime)
          this.setDriedStartDateTime()
        if (batch.driedStartMoisture)
          this.driedStartMoisture = batch.driedStartMoisture
        if (batch.driedMoundHeight)
          this.driedMoundHeight = batch.driedMoundHeight
        if (batch.driedMoisture)
          this.driedMoisture = batch.driedMoisture
        if (batch.driedHlWeight)
          this.driedHlWeight = batch.driedHlWeight
        this.dryerCleaned = batch.dryerCleaned === null ? true : parseInt(batch.dryerCleaned) === 1
        if (batch.driedDateTime) {
          this.setDriedDateTime(batch.driedDateTime)
        } else if (!this.showDryingStartTime || batch.driedStartDateTime)
          this.setDriedDateTime()

        this.vessel_regraterId = batch.regraterId
        await this.getFillableVessels()
        break

      case 2:
        this.childVessels = await this.$store.dispatch('getItemsByParent', {
          entityName: 'vessel',
          method: 'childs',
          parentEntity: 'batch',
          parentId: batch.batchId,
          returnData: true
        })

        // console.log('childsByParent', this.childVessels)
        break
    }
  },
  computed: {
    showBatchSampleNumber() {
      return this.$store.getters.batchSampleNumber
    },
    dryersAmount() {
      //return 3
      return this.$store.getters.dryersAmount
    },
    dryers() {
      return this.$store.getters.dryers
    },
    showBruttoTara() {
      //return false
      return this.$store.getters.incomingBruttoTara
    },
    showWashingTime() {
      //return false
      return this.$store.getters.washingTime
    },
    showDryingTemperature() {
      //return true
      return this.$store.getters.dryingTemperature
    },
    showDryingHeatMeter() {
      //return true
      return this.$store.getters.dryingHeatMeter
    },
    showDriedMoundHeight() {
      //return true
      return this.$store.getters.dryingMoundHeight
    },
    showDryingStartTime() {
      //return false
      return this.$store.getters.dryingStartTime
    },
    showDryingStartMoisture() {
      //return true
      return this.$store.getters.dryingStartMoisture
    },
    showStockLocation() {
      return this.$store.getters.vesselStockLocation
    },
  },
  methods: {
    getWetVessels() {
      // console.log('getwetvessels',this.$props.bags)
      let bags = [];
      for (let idx in this.$props.bags) {
        if (this.$props.bags[idx].workflowState == 1) {
          bags.push(this.$props.bags[idx])
        }
      }
      return bags
    },
    getDryVessels() {
      let bags = [];
      for (let idx in this.$props.bags) {
        if (this.$props.bags[idx].workflowState >= 2) {
          bags.push(this.$props.bags[idx])
        }
      }
      return bags
    },
    async getFillableVessels() {
      this.fillableVessels = await this.$store.dispatch('getItemsByParent', {
        entityName: 'vessel',
        method: 'fillable',
        parentEntity: 'regrater',
        parentId: this.vessel_regraterId,
        returnData: true
      })
    },
    parseNumber(number) {

      if (parseInt(number) != number)
        number = parseFloat(number)
      else
        number = parseInt(number)

      return number.toLocaleString('de-DE')
    },
    getChildVesselsText(vesselId) {
      let texts = []
      for (let idx in this.childVessels) {
        if (this.childVessels[idx].parent_vesselId == vesselId) {
          texts.push(this.childVessels[idx].uniqueNumber + ' ' + this.parseNumber(this.childVessels[idx].weight) + ' kg')
        }
      }

      if (texts.length > 0)
        return texts.join(', ')

      return null
    },
    async saveBag() {
      let bag = {}

      if (this.$props.workflowState == 0) {
        bag = {
          weight: this.wetWeight,
          workflowState: 1
        }
      } else { // workflowState === 1
        bag = {
          weight: this.dryWeight,
          workflowState: 2,
          regraterId: this.vessel_regraterId
        }
      }
      bag['batchId'] = this.batch.batchId
      bag['parent_vesselId'] = this.parent_vesselId

      if (this.showStockLocation)
        bag['stockLocation'] = this.stockLocation

      const response = await this.$store.dispatch('cruItem',
          {name: 'vessel', data: bag, parentEntity: 'batch', parentId: this.batch.batchId})

      if (response && response.success === true) {
        this.dryWeight = ''
        this.stockLocation = ''

        if (this.$props.workflowState === 0)
          this.getWashed()
        else {
          this.getDried()
          this.getFillableVessels()
        }
      }

      if (this.getDryVessels().length === 1) {
        this.setDriedDateTime()
      }

      this.vessel_parentForm = false
      this.parent_vesselId = ''
      // this.$store.dispatch('createBag', {batchId: this.$props.batch.id, bag}).then(this.checkIfBagged.bind(this))
    },
    getWashed() {
      this.$store.dispatch('getItemsByParent', {
        entityName: 'vessel', parentEntity: 'batch',
        parentId: this.batch.batchId, method: 'washed'
      })
    },
    getDried() {
      this.$store.dispatch('getItemsByParent', {
        entityName: 'vessel', parentEntity: 'batch',
        parentId: this.batch.batchId, method: 'dried'
      })
    },

    // washing
    setWashedStartDateTime(datetime) {
      if (datetime) {
        const customParseFormat = require('dayjs/plugin/customParseFormat')
        dayjs.extend(customParseFormat)
        this.washedStartDate = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('DD.MM.YYYY')
        this.washedStartTime = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('HH:mm')
      } else {
        this.washedStartDate = dayjs().format('DD.MM.YYYY')
        this.washedStartTime = dayjs().format('HH:mm')
      }
    },
    setWashedDateTime(datetime) {
      if (datetime) {
        const customParseFormat = require('dayjs/plugin/customParseFormat')
        dayjs.extend(customParseFormat)
        this.washedDate = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('DD.MM.YYYY')
        this.washedTime = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('HH:mm')
      } else {
        this.washedDate = dayjs().format('DD.MM.YYYY')
        this.washedTime = dayjs().format('HH:mm')
      }
    },
    async storeWashing() {
      this.washingStored = false
      let data = {}

      if (this.showWashingTime)
        data = {
          ...data,
          washedStartDate: this.washedStartDate,
          washedStartTime: this.washedStartTime,
          washedDate: this.washedDate,
          washedTime: this.washedTime,
        }
      if (this.showBruttoTara)
        data = {
          ...data,
          incomingGrossWeight: this.incomingGrossWeight,
          incomingTareWeight: this.incomingTareWeight,
        }

      const response = await this.$store.dispatch('cruItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.washingStored = true
      }
    },
    totalWeight(workflowState, formatted) {
      let total = 0;
      let bags;

      if (workflowState == 1) {
        bags = this.$props.bags.filter(bag => bag.workflowState == 1)
      } else
        bags = this.$props.bags.filter(bag => bag.workflowState >= 2)

      for (let idx in bags) {
          total += parseFloat(this.$props.bags[idx].weight)
      }
      if (formatted === false)
        return total

      return this.parseNumber(total)
    },
    async finishWashing() {
      const data = {
        washedWeight: this.totalWeight(1, false),
        washedQuality: this.noteQuality,
        washedImage: this.$refs.camera.files[0]
      }
      if (this.showBatchSampleNumber)
        data.sampleNumber = this.sampleNumber

      const response = await this.$store.dispatch('uploadItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.$props.batch.washedQuality = this.noteQuality
        this.$props.batch.washedImage = this.noteImage
        this.$props.batch.washedWeight = this.totalWeight(1, false)
      }
      // this.$props.workflowState = 1
    },
    saveWashed() {
      // prevent-function to activate browser validation for input fields
    },

    // drying
    setDriedStartDateTime(datetime) {
      if (datetime) {
        const customParseFormat = require('dayjs/plugin/customParseFormat')
        dayjs.extend(customParseFormat)
        this.driedStartDate = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('DD.MM.YYYY')
        this.driedStartTime = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('HH:mm')
      } else {
        this.driedStartDate = dayjs().format('DD.MM.YYYY')
        this.driedStartTime = dayjs().format('HH:mm')
      }
    },
    setDriedDateTime(datetime) {
      if (datetime) {
        const customParseFormat = require('dayjs/plugin/customParseFormat')
        dayjs.extend(customParseFormat)
        this.driedDate = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('DD.MM.YYYY')
        this.driedTime = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('HH:mm')
      } else {
        this.driedDate = dayjs().format('DD.MM.YYYY')
        this.driedTime = dayjs().format('HH:mm')
      }
    },
    saveDried() {
      // prevent-function to activate browser validation for input fields
    },
    async storeDrying() {
      this.dryingStored = false
      const data = {
        dryerId: this.dryerId,
        driedMoisture: this.driedMoisture,
        driedHlWeight: this.driedHlWeight,
        driedDate: this.driedDate,
        driedTime: this.driedTime,
        dryerCleaned: this.dryerCleaned
      }

      // add optional fields
      if (this.showDryingTemperature)
        data.dryingTemperature = this.dryingTemperature
      if (this.showDryingHeatMeter) {
        data.dryingHeatMeterStart = this.dryingHeatMeterStart
        data.dryingHeatMeterEnd = this.dryingHeatMeterEnd
      }
      if (this.showDriedMoundHeight)
        data['driedMoundHeight'] = this.driedMoundHeight
      if (this.showDryingStartTime) {
        data['driedStartDate'] = this.driedStartDate
        data['driedStartTime'] = this.driedStartTime
      }
      if (this.showDryingStartMoisture)
        data['driedStartMoisture'] = this.driedStartMoisture

      const response = await this.$store.dispatch('cruItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.dryingStored = true
      }
    },
    async finishDrying() {
      const data = {
        dryerId: this.dryerId,
        driedMoisture: this.driedMoisture,
        driedHlWeight: this.driedHlWeight,
        driedDate: this.driedDate,
        driedTime: this.driedTime,
        dryerCleaned: this.dryerCleaned,
        driedWeight: this.totalWeight(2, false)
      }

      const response = await this.$store.dispatch('cruItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.$props.batch.driedWeight = this.totalWeight(2, false)
      }
      // this.$props.batch.bagged = true
    },

    openedPdf(params) {
      if (params.method === 'bigbagslip') {
        const vessel = this.$props.bags.find(vessel => vessel.uniqueId == params.elementId)
        vessel.openedBigBagSlip = 1
      }
    },
    openDialog({element, nbr, weight, uid}) {
      this.modalText = 'Soll ' + element + ' gelöscht werden?'
      this.modalElement = nbr + ' mit ' + this.parseNumber(weight) + ' kg'
      this.modalOpened = true
      this.modalUid = uid
    },
    abortDialog() {
      this.modalOpened = false
    },
    async confirmDialog() {
      this.modalOpened = false
      await this.$store.dispatch('delItem', {name: 'vessel', uid: this.modalUid})
      await this.$store.dispatch('getItemsByParent',
          {entityName: 'vessel', parentEntity: 'batch', parentId: this.batch.batchId})
    },
    checkIfBagged() {
      // let bagsWeight = 0
      //
      // for(let i in this.$props.batch.bags) {
      //   bagsWeight += this.$props.batch.bags[i].weight
      // }
      //
      // if (bagsWeight === this.$props.batch.dryWeight) {
      //   this.$props.batch.bagged = true
      // }
    },
    openCamera(e) {
      e.preventDefault()
      this.$refs.camera.click()
      // this.scrollToTop()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.noteImage = URL.createObjectURL(file);
    },
    resetPhoto(e) {
      e.preventDefault()
      this.noteImage = null
    },
    // big bag popup editor
    editBag(bag) {
      this.bagElement = bag
      this.bagEditorOpened = true
    },
    abortBagEdit() {
      this.bagElement = null
      this.bagEditorOpened = false
    },
    async saveBagEdit({id, weight, regraterId, stockLocation}) {
      let data = {
        weight, regraterId
      }
      if (this.showStockLocation)
        data['stockLocation'] = stockLocation

      const response = await this.$store.dispatch('cruItem',
          {name: 'vessel', id, data: data})

      if (response && response.success === true) {
        this.bagElement.weight = weight
        this.abortBagEdit()
        this.getDried()
      }
    },
    regraterName(contactId) {
      const regrater = this.$store.state.regrater.find(regrater => regrater.contactId == contactId)

      if (regrater) return regrater.name
      else return null
    }
    
  }
}
</script>

<style scoped>
.w55 {
  flex: 0 0 55%;
  max-width: 55%;
}

.w45 {
  flex: 0 0 45%;
  max-width: 45%;
}

details summary h2 {
  font-family: inherit;
  border-bottom-width: 1px;
}
</style>