<template>
  <div v-if="showList">
    <h1>Kontakte</h1>
    <table class="table table-sm table-hover clickable">
      <thead>
      <tr>
<!--        <th scope="col">#</th>-->
        <th scope="col">Name</th>
        <th scope="col">Ort</th>
        <th scope="col">E-Mail</th>
        <th scope="col">Mobiltelefon</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="entry in getListItems()"
          :key="entry.contactId"
          @click="cruElement(entry.contactId)">
<!--        <th scope="row">{{ entry.contactId }}</th>-->
        <td>{{ entry.name }}</td>
        <td>{{ entry.city }}</td>
        <td>{{ entry.email }}</td>
        <td>{{ entry.mobilePhone }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <contact-editor v-if="formOpened" :element-id="elementId" @closeForm="closeForm"/>
  <app-btn-back class="pt-2"/>
  <app-btn-add v-if="!formOpened" @click="openForm"/>
</template>

<script>
import ContactEditor from "@/components/ContactEditor";

export default {
  name: "ContactPage",
  components: {ContactEditor},
  data() {
    return {
      showList: true,
      formOpened: false,
      elementId: null,
      list: null
    }
  },
  created() {
    this.$store.dispatch('getItemList', {entityName:'contact'})
  },
  methods: {
    getListItems(){
      return this.$store.state.contact
    },
    cruElement(id) {
      this.elementId = id
      this.showList = false
      this.formOpened = true
    },
    openForm() {
      this.showList = false
      this.formOpened = true
    },
    closeForm() {
      this.formOpened = false
      this.showList = true
      this.elementId = null
    }
  }
}
</script>

<style scoped>

</style>