<template>
  <h1>Jahresübersicht</h1>
  <div v-show="!regraterId" class="row mb-3">
    <div class="col-4 bold">Gesamt</div>
    <div class="col-3 bold text-right">{{ grandTotal() }} kg</div>
  </div>

  <div
      v-for="supplier in suppliers()"
      :key="supplier.contactId">
  <details
      v-if="supplierTotal(supplier.contactId, false) > 0 || supplierId == supplier.contactId"
      :open="supplierId == supplier.contactId"
  >
    <summary><h2>{{ supplier.name }}</h2></summary>
    <ul v-if="contractDetails" class="output col-8 mt-0">
      <li><label class="col-3">Aufkäufer</label> {{ regraterName(contractDetails.regraterId) }}</li>
<!--      <li><label class="col-3">Standard</label> {{ contractDetails.qualityStandard }}</li>-->
      <li v-show="contractDetails.supplierNumber"><label class="col-3">Lieferant-Nr.</label> {{ contractDetails.supplierNumber }}</li>
      <li v-show="contractDetails.eanCode"><label class="col-3">EAN-Code</label> {{ contractDetails.eanCode }}</li>
      <li v-show="contractDetails.contractWeight"><label class="col-3">Vertrags-Menge</label> {{ parseNumber(contractDetails.contractWeight) }} kg</li>
    </ul>
    <h2 v-show="regraterId">Big Bags</h2>
    <ul class="subnav mb-0">
      <router-link
          v-for="vessel in getVesselsBySupplier(supplier.contactId)"
          :key="vessel.vesselId"
          :to="{name: 'BatchShow', params: {id: vessel.uniqueNumber}}">
        <li class="row">
          <label class="col-5">{{ vessel.uniqueNumber }}
            <span v-if="getVesselChilds(vessel.vesselId)">&larr; {{ getVesselChildsText(vessel) }}</span>
            <span v-if="parseInt(vessel.parent_vesselId) > 0">&rarr; {{ getVesselsParentText(vessel) }}</span>
          </label>
          <div class="col-2 text-right">{{ parseNumber(vessel.weight) }} kg</div>
          <div class="col-1"></div>
        </li>
      </router-link>
    </ul>
    <div class="row mb-3">
      <div class="col-4 bold">Summe</div>
      <div class="col-3 bold text-right">{{ supplierTotal(supplier.contactId) }} kg</div>
      <div v-show="!regraterId" class="col-1 text-right">
        <api-csv-link method="bigbaglist" :elementId="supplier.uniqueId" class="text-right"/>
      </div>
    </div>
  </details>
  </div>
  <app-btn-back class="pt-2" router-link="OverviewPage"/>
</template>

<script>
import ApiCsvLink from "@/components/ApiCsvLink";
export default {
  name: "OverviewPage",
  components: {ApiCsvLink},
  props: {
    supplierId: {
      type: Number,
      required: false
    },
    regraterId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      contractDetails: null
    }
  },
  async created() {
    this.$store.state.vessel = []
    await this.$store.dispatch('initItems', {entityName: 'report', method: 'overview'})
    await this.$store.dispatch('getItemList', {entityName: 'vessel', method: 'processed'})
    await this.$store.dispatch('initItems', {entityName: 'supplier'})
    await this.$store.dispatch('initItems', {entityName: 'regrater'})

    if (this.supplierId && this.regraterId)
      this.contractDetails = this.$store.state.report.find(
          report => report.supplierId == this.supplierId && report.regraterId == this.regraterId)

    if (this.contractDetails && this.contractDetails.qualityStandard) {
      const object = this.$store.state.qualityStandards.find(quality => quality.id == this.contractDetails.qualityStandard)
      if (object && object.name)
        this.contractDetails.qualityStandard = object.name
    }
  },
  methods: {
    suppliers() {
      return this.$store.state.supplier
      // return this.$store.state.batch.filter(batch => batch.washedWeight === null)
    },
    getVesselsBySupplier(contactId) {
      let vessels = this.$store.state.vessel.filter(vessel => vessel.supplierId == contactId)

      if (this.regraterId)
        vessels = vessels.filter(vessel => vessel.regraterId == this.regraterId)

      return vessels
    },
    supplierTotal(contactId, formatted) {
      if (this.supplierId && contactId != this.supplierId) return null

      const vessels = this.getVesselsBySupplier(contactId)
      let total = 0

      for (let idx in vessels) {
        total += parseFloat(vessels[idx]['weight'])
      }
      if (formatted === false)
        return total

      return this.parseNumber(total)
    },
    grandTotal() {
      const vessels = this.$store.state.vessel
      let total = 0

      for (let idx in vessels) {
        total += parseFloat(vessels[idx]['weight'])
      }
      return this.parseNumber(total)
    },
    formattedInt(number) {
      return parseInt(number).toLocaleString('de-DE')
    },
    regraterName(contactId) {
      const regrater = this.$store.state.regrater.find(regrater => regrater.contactId == contactId)
      if (regrater) return regrater.name
      else return null
    },
    getVesselChilds(vesselId) {
      const childs = this.$store.state.vessel.filter(vessel => vessel.parent_vesselId == vesselId)

      if (childs.length > 0)
        return childs

      return null
    },
    getVesselChildsText(vessel) {
      let texts = []
      const childs = this.getVesselChilds(vessel.vesselId)

      for (const child of childs) {
        texts.push(child['uniqueNumber'])
      }

      return texts.join(', ')
    },
    getVesselsParentText(vessel) {
      const parent = this.$store.state.vessel.filter(entry => entry.vesselId === vessel.parent_vesselId)

      if (parent.length)
        return parent[0]['uniqueNumber']

      return ''
    },
    parseNumber(number) {

      if (parseInt(number) != number)
        number = parseFloat(number)
      else
        number = parseInt(number)
      //return number
      return number.toLocaleString('de-DE')
    },
  }
}
</script>

<style scoped>
h2 {
  padding-top: 0.5rem;
}

.subnav li:after {
  margin-left: -16px;
}
</style>