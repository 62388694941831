<template>
  <div class="row mt-3">
    <div class="col-3">
      <button @click="goBack" type="reset">Zurück</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppBackButton",
  props: {
    routerLink: {
      type: String,
      required: true,
      default: 'Home'
    }
  },
  methods: {
    goBack() {
      this.$router.push({name: this.routerLink})
    }
  }
}
</script>

<style scoped>

</style>