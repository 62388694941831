import axios from 'axios';

export default {
    async signIn({commit, state}, {email, password, resetPassword}) {
        let result = {};

        await axios.post(state.appApiBase + state.appInstance
            + '/auth/login', {
            email,
            password,
            resetPassword
        })
            .then(response => {
                if (response.data.success) {

                    let incomingLocationNames = null
                    let dryers = null

                    if (response.data.settings.dryers_names) {
                        dryers = {}
                        let index = 1
                        for (let name of response.data.settings.dryers_names.split(',')) {
                            dryers[index] = name
                            index++
                        }
                    }
                    if (response.data.settings.incoming_location_names) {
                        incomingLocationNames = {}
                        let index = 1
                        for (let name of response.data.settings.incoming_location_names.split(',')) {
                            incomingLocationNames[index] = name
                            index++
                        }
                    }

                    const loginData = {
                        userId: response.data.userId,
                        contactName: response.data.contactName,
                        userUid: response.data.userUid,
                        userToken: response.data.loginToken,
                        userPermission: response.data.permission,
                        userRole: response.data.role,
                        tokenExpiration: response.data.timeoutDateTime,
                        appSetting: {
                            dryersAmount: response.data.settings.dryers_amount,
                            printerEmail: response.data.settings.printer_email,
                            bondnotePdf: response.data.settings.bondnote_pdf,
                            incomingBruttoTara: response.data.settings.incoming_brutto_tara,
                            dryingNotePdf: response.data.settings.drying_note_pdf,
                            washingTime: response.data.settings.washing_time,
                            dryingStartTime: response.data.settings.drying_starttime,
                            dryingStartMoisture: response.data.settings.drying_startmoisture,
                            dryingMoundHeight: response.data.settings.drying_mound_height,
                            vesselStockLocation: response.data.settings.vessel_stock_location,
                            batchSampleNumber: response.data.settings.batch_sample_number,
                            batchLane: response.data.settings.batch_lane,
                            batchTime: response.data.settings.batch_time,
                            dryingTemperature: response.data.settings.drying_temperature,
                            dryingHeatMeter: response.data.settings.drying_heatmeter,
                            dryers,
                            incomingLocationNames,
                        }
                    }
                    // console.log('usersetting',loginData.appSetting,loginData.appSetting.printerEmail)
                    commit('setUserStorage', loginData)
                    commit('setUser', loginData)
                    result = {success: true}
                } else {
                    result = {err: response.data.err}
                }
            })
            .catch(e => {
                // console.log(e)
                result = {err: e.code + ': ' + e.message}
            });

        return result
    },
    async signOut({commit, state}) {
        await axios.get(state.appApiBase + state.appInstance
            + '/auth/logout', {
            headers: {
                Authorization: state.userToken + '.' + state.userUid
            }
        })
        commit('unsetUser')
    },
    async tryLogin({commit, state}) {
        const userId = localStorage.getItem('userId')
        const userUid = localStorage.getItem('userUid')
        const userToken = localStorage.getItem('userToken')

        // check session on server
        const response = await axios.get(state.appApiBase + state.appInstance
            + '/auth/alive', {
            headers: {
                Authorization: userToken + '.' + userUid
            }
        })
        if (response.data.isAlive) {
            commit('setUser', {
                userId,
                userUid,
                userToken,
                userPermission: localStorage.getItem('userPermission'),
                userRole: localStorage.getItem('userRole'),
                tokenExpiration: localStorage.getItem('tokenExpiration'),
                appSetting: JSON.parse(localStorage.getItem('appSetting'))
            })
            return true
        } else {
            return false
        }
    },
    async resetPassword({commit, state}, {email, password, hash}) {
        let result = {};

        await axios.post(state.appApiBase + state.appInstance
            + '/auth/resetPassword', {
            email,
            password,
            hash
        })
            .then(response => {
                if (response.data.success) {
                    result = {success: response.data.success}
                } else {
                    result = {err: response.data.err}
                }
            })
            .catch(e => {
                // console.log(e)
                result = {err: e.code + ': ' + e.message}
            });

        commit('unsetUser')
        return result
    },
    async initItems({state, dispatch}, {entityName, forceReload, method}) {
        if (!state[entityName].length || forceReload === true) {
            await dispatch('getItemList', {entityName, method})
        }
    },
    async getItemList({state, commit}, {entityName, method, returnData}) {
        // console.log('getItemList', entityName)
        method = method ? method : 'list'

        const response = await axios.get(state.appApiBase + state.appInstance
            + '/' + entityName + '/' + method, {
            headers: {
                Authorization: state.userToken + '.' + state.userUid
            }
        })
        // console.log('gotList', entityName, response.data)
        if (response.data) {
            if (returnData) return response.data
            else await commit('setItemList', {entityName, list: response.data})
        }
        return true
    },
    async getItemsByParent({state, commit}, {entityName, parentEntity, parentId, regraterId, method, returnData}) {
        method = method ? method : 'byparent'

        let url = state.appApiBase + state.appInstance
            + '/' + entityName + '/' + method

        if (parentId) url += '?parentId=' + parentId
        else if (regraterId) url += '?regraterId=' + regraterId

        const response = await axios.get(url, {
            headers: {
                Authorization: state.userToken + '.' + state.userUid
            }
        })
        // console.log('gotChilds', entityName, response.data)
        if (response.data || response.data === null) {
            if (returnData) return response.data
            else commit('setItemChild', {entityName, parentEntity, parentId, list: response.data})
        }
    },
    async getItem({state}, {name, id}) {
        const response = await axios.get(state.appApiBase + state.appInstance
            + '/' + name + '/item/' + id, {
            headers: {
                Authorization: state.userToken + '.' + state.userUid
            }
        })
        if (response.data) {
            return response.data
        }
    },
    async cruItem({state, commit}, {name, id, data, parentEntity, parentId}) {
        // console.log('cruItem', name, id, data)

        const response = await axios.post(state.appApiBase + state.appInstance
            + '/' + name + '/item/' + id, data, {
            headers: {
                Authorization: state.userToken + '.' + state.userUid
            }
        })
        if (response.data && response.data.success) {
            if (response.data.id) {
                data[name + 'Id'] = response.data.id
            }
            commit('cruItem', {name, id, data, parentEntity, parentId})
            return response.data
        }
    },
    async uploadItem({state, commit}, {name, id, data, parentEntity, parentId}) {
        const response = await axios.post(state.appApiBase + state.appInstance
            + '/' + name + '/item/' + id, data, {
            headers: {
                Authorization: state.userToken + '.' + state.userUid,
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response.data && response.data.success) {
            // console.log(response.data)
            if (response.data.id) {
                data[name + 'Id'] = response.data.id
            }
            if (response.data.washedPicture1) {
                data['washedPicture1'] = response.data.washedPicture1
            }
            commit('cruItem', {name, id, data, parentEntity, parentId})
            return response.data
        }
    },
    async delItem({state}, {name, uid}) {
        await axios.delete(state.appApiBase + state.appInstance
            + '/' + name + '/item/' + uid, {
            headers: {
                Authorization: state.userToken + '.' + state.userUid
            }
        })
    },
    async sendEmail({state}, {type, uid}) {
        const response = await axios.get(state.appApiBase + state.appInstance
            + '/email/' + type + '/' + uid, {
            headers: {
                Authorization: state.userToken + '.' + state.userUid
            }
        })
        if (response.data) {
            return response.data
        }
    },
    async sendEmailToPrinter({state}, {type, uid}) {
        const response = await axios.post(state.appApiBase + state.appInstance
            + '/pdf/' + type + '/' + uid, {
            authorization: state.userToken + '.' + state.userUid,
            printMode: 'email'
        })
        if (response.data) {
            return response.data
        }
    },
    setTitle({commit}, title) {
        commit('setTitle', title)
    },


    createBatch({commit, state}, batch) {
        // console.log(batch)
        const id = 'CH' + state.batch.length + 1;
        const userId = 'U1'
        const publishedAt = Math.floor(Date.now() / 1000)
        const supplier = state.suppliers.find(supplier => supplier.id === batch.supplierId).name
        const regrater = state.regraters.find(regrater => regrater.id === batch.regraterId).name

        batch = {...batch, id, supplier, regrater, userId, publishedAt}
        commit('setBatch', {batch})

        return id
    },
    updateBatch({commit, state}, {id, batch}) {
        const currentBatch = state.batch.find(batch => batch.id === id)

        if (batch.dryWeight && !currentBatch.vessel) {
            batch.vessel = []
        }

        batch = {
            ...currentBatch,
            ...batch
        }

        commit('updateBatch', {id, batch})
    },
    getBatch({state}, {id}) {
        return state.batch.find(batch => batch.id === id)
    },
    createBag({commit, state}, {batchId, bag}) {
        // console.log(batchId)
        const batch = state.batch.find(batch => batch.id === batchId)
        // console.log(batch)
        const bagsCount = batch.vessel ? batch.vessel.length : 0;

        bag = {
            ...bag,
            id: batchId + 'B' + (bagsCount + 1),
            publishedAt: Math.floor(Date.now() / 1000)
        }
        commit('setBag', {bag, batchId})
        // commit('appendBagToBatch', {bagId: bag.id, batchId: bag.batchId})
    },
    getBags({state}, {batchId}) {
        if (batchId) {
            return state.batch.find(batch => batch.id === batchId).batch
        } else {
            let bags = []
            for (let idx in state.batch) {
                if (state.batch[idx].vessel) {
                    bags = [
                        ...bags,
                        ...state.batch[idx].vessel
                    ]
                }
            }
            return bags
        }
    },
    async readQR(state, {file}) {
        if (!file) return null

        let formData = new FormData();
        formData.append('file', file);

        // axios.post('https://api-demo.pit-story.com/actions/read-qr',
        axios.post('/php/read-qr.php',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function () {
                // console.log('SUCCESS!!');
            })
            .catch(function () {
                // console.log('FAILURE!!');
            });
    },
    async setAPIconf({state}) {
        if (!state.appApiBase) {
            const response = await axios.get(process.env.VUE_APP_CONFIG_FILE)

            if (response.data) {
                const conf = response.data

                state.appApiBase = conf.appApiBase
                state.appInstance = conf.appInstance
            }
        }
    }
}