<template>
  <dialog id="modalDialog" open>
    <div class="row">
      <slot></slot>
    </div>
    <div class="row mt-5 buttons">
      <div class="col-3">
        <button @click="abortDialog" type="reset">Abbrechen</button>
      </div>
      <div class="col-5">
        <button @click="confirmDialog" type="submit">Bestätigen</button>
      </div>
    </div>
  </dialog>
</template>

<script>
export default {
  name: "AppModalDialog",
  methods: {
    abortDialog() {
      this.$emit('abortDialog')
    },
    confirmDialog() {
      this.$emit('confirmDialog')
    }
  }
}
</script>

<style scoped>
dialog {
  z-index: 1000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  border: 0;
  padding:20vh 1.5rem 0;
}
dialog .buttons div:first-of-type {
  padding-left:0;
}
dialog .buttons div:last-child {
  padding-right:0;
}
</style>