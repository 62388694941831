<template>
  <h1 class="mt-10">404</h1>
  <router-link :to="{name: 'Home'}">home</router-link>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>