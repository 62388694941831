<template>
  <div class="btn-add">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
      <g id="Group_350" transform="translate(-284 -684)">
        <circle id="Ellipse_3" fill="#F2D79A" cx="316" cy="716" r="32"/>
        <g id="add" transform="translate(299 699)">
          <path id="Path_152" fill="none" stroke="#242229" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M17,1v32"
          />
          <path id="Path_153" fill="none" stroke="#242229" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M33,17H1"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppBtnAdd"
}
</script>

<style scoped>

</style>