<template>
  hello from arrival
</template>

<script>
export default {
  name: "PageArrivalShow",
  props: {
    id: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>