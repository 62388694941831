<template>
  <nav v-if="displayNav">---nav
    <div v-if="authUser">logged in</div>
    <div v-else>show login</div>
    <router-link :to="{name:'Home'}">Home</router-link>
    <ul>
      <li
          v-for="navitem in navitems"
          :key="navitem.name">{{ navitem.name }}</li>
    </ul>nav---
  </nav>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "TheNavigation",
  computed: {
    navitems() {
      return this.$store.state.nav
    },
    ...mapGetters(['authUser'])
  }
}
</script>

<style scoped>
</style>