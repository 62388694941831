<template>
  <dialog id="modalBagEditor" open>
    <h1>Lieferung {{ element.loadingId }}</h1>
    <form class="row">

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="regraterId">Aufkäufer</label>
        </div>
        <select
            v-model="regraterId"
            class="custom-select custom-select-lg" id="regraterId" required>
          <option key="0" value="">Auswählen..</option>
          <option
              v-for="regrater in regraters"
              :key="regrater.contactId"
              :value="regrater.contactId">{{ regrater.name }}
          </option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="deliverySlipNumber">Lieferschein</label>
        </div>
        <input v-model="deliverySlipNumber" class="form-control form-control-lg" id="deliverySlipNumber">
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="licensePlateNumber">Kennzeichen</label>
        </div>
        <input v-model="licensePlateNumber" type="text" class="form-control form-control-lg" id="licensePlateNumber" placeholder="">
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="licensePlateTrailer">Kennzeichen Anhänger</label>
        </div>
        <input v-model="licensePlateTrailer" type="text" class="form-control form-control-lg" id="licensePlateTrailer" placeholder="">
      </div>

    </form>
    <div class="row mt-1 buttons">
      <div class="col-3">
        <button @click="abortDialog" type="reset">Abbrechen</button>
      </div>
      <div class="col-5">
        <button @click="confirmDialog" type="submit">Speichern</button>
      </div>
    </div>
  </dialog>
</template>

<script>
//import dayjs from "dayjs";

export default {
  name: "PopupLoadingEditor",
  data() {
    return {
      regraterId: null,
      deliverySlipNumber: null,
      licensePlateNumber: null,
      licensePlateTrailer: null,
    }
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    regraters: {
      type: Array,
      required: true
    }
  },
  created() {
    this.regraterId = this.$props.element.regraterId
    this.deliverySlipNumber = this.$props.element.deliverySlipNumber
    this.licensePlateNumber = this.$props.element.licensePlateNumber
    this.licensePlateTrailer = this.$props.element.licensePlateTrailer
  },
  computed: {
    showBatchSampleNumber() {
      return this.$store.getters.batchSampleNumber
    },
  },
  methods: {
    abortDialog() {
      this.$emit('abortDialog')
    },
    confirmDialog() {
      this.$emit('confirmDialog', {
        regraterId: this.regraterId,
        deliverySlipNumber: this.deliverySlipNumber,
        licensePlateNumber: this.licensePlateNumber,
        licensePlateTrailer: this.licensePlateTrailer,
      })
    }
  }
}
</script>

<style scoped>
dialog {
  z-index: 1000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  border: 0;
  padding: 20vh 1.5rem 0;
}

dialog .buttons div:first-of-type {
  padding-left: 0;
}

dialog .buttons div:last-child {
  padding-right: 0;
}
</style>