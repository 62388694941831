<template>
  <span>{{ datetimeOutput() }}</span>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "AppDateTime",
  props: {
    timestamp: {
      required: true,
      type: Number
    }
  },
  methods: {
    datetimeOutput(){
      return dayjs.unix(this.timestamp).format('DD.MM.YYYY H:m:s')
    }
  }
}
</script>

<style scoped>

</style>