<template>
  <h1>Verladen</h1>
  <ul class="subnav" style="width:95%">
    <router-link
        v-for="loading in loadings()"
        :key="loading.loadingId"
        :to="{name: 'LoadingShow', params: {id: loading.loadingId}}">
      <li v-html="getLoadingLabel(loading)"></li>
    </router-link>
  </ul>
  <div v-show="canWrite" class="row">
    <div class="col-8">
      <router-link :to="{name:'LoadingShow', params: {id: 0}}">
        <button>Starten</button>
      </router-link>
    </div>
  </div>
  <div v-if="latest">
    <h2>Letzte</h2>
    <ul class="subnav" style="width:95%">
      <router-link
          v-for="loading in latest"
          :key="loading.loadingId"
          :to="{name: 'LoadingShow', params: {id: loading.loadingId}}">
        <li v-html="getLoadingLabel(loading)"></li>
      </router-link>
    </ul>
  </div>
  <app-btn-back class="pt-2"/>
</template>

<script>
export default {
  name: "LoadingPage",
  data() {
    return {
      latest: null
    }
  },
  async created() {
    await this.$store.dispatch('getItemList', {entityName: 'loading'})
    await this.$store.dispatch('getItemList', {entityName: 'regrater'})
    this.latest = await this.$store.dispatch('getItemList', {entityName: 'loading', method: 'latest', returnData: true})
  },
  computed: {
    canWrite() {
      return this.$store.getters.canWrite
    }
  },
  methods: {
    loadings() {
      return this.$store.state.loading
    },
    getLoadingLabel(loading) {
      let label = '<label>L'+ loading.loadingId
      if (loading.deliverySlipNumber) label += ' '+ loading.deliverySlipNumber
      if (loading.licensePlateNumber) label += (loading.deliverySlipNumber && ' | ')+ loading.licensePlateNumber
      label += '</label>'
      if (loading.regraterId) label += ' &rarr; '+ this.getRegrater(loading.regraterId)

      return label
    },
    getRegrater(id) {
      if (!id || !this.$store.state.regrater) return '–'
      const item = this.$store.state.regrater.find(item => item.contactId === id)
      if (!item) return '–'
      return item.name
    },
  }
}
</script>

<style scoped>

</style>