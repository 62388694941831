<template>
  <the-header />
  <the-navigation />
  <main>
    <router-view/>
  </main>
</template>

<script>
import TheNavigation from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";

export default {
  name: 'App',
  components: { TheHeader, TheNavigation },
  async created() {
    //await this.$store.dispatch('setAPIconf')
    //this.$store.dispatch('tryLogin').then(this.sessionIsAlive.bind(this))
  },
  methods: {
    sessionIsAlive(isAlive) {
      // console.log('result alive:',isAlive)
      // console.log(this.$route.name)
      if (!isAlive && this.$route.name !== undefined) {
        // console.log('forward to signin')
        this.$router.push('/signin')
      }
    }
  }
}
</script>

<style>
</style>
