<template>
  <h1>Lager</h1>
  <div v-if="isIntern">
    <div
        v-for="regrater in regraters()"
        :key="regrater.contactId">
      <div
          v-if="regraterTotal(regrater.contactId, false) > 0">
        <h2>{{ regrater.name }}</h2>
        <ul class="subnav mb-0">
          <router-link
              v-for="vessel in getVesselsByRegrater(regrater.contactId)"
              :key="vessel.vesselId"
              :to="{name: 'BatchShow', params: {id: vessel.uniqueNumber}}">
            <li class="row">
              <label class="col-5">
                {{ vessel.uniqueNumber }} {{ vessel.supplier }}
                <span v-if="getVesselChilds(vessel.vesselId)"><br/>{{ getVesselChildsText(vessel) }}</span>
                <span v-if="parseInt(vessel.parent_vesselId) > 0"><br/>{{ parseNumber(vessel.weight) }} kg &rarr; {{ getVesselsParentText(vessel) }}</span>
                <span v-if="vessel.stockLocation"><br/>Lager {{ vessel.stockLocation }}</span>
              </label>
              <div class="col-2 text-right">{{ getVesselWeight(vessel) }}</div>
              <div class="col-1"></div>
            </li>
          </router-link>
        </ul>
        <div class="row mb-3">
          <div class="col-5 bold">Summe</div>
          <div class="col-2 bold text-right">{{ regraterTotal(regrater.contactId) }} kg</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div
        v-for="supplier in suppliers()"
        :key="supplier.contactId">
      <div
          v-if="supplierTotal(supplier.contactId, false) > 0">
        <h2>{{ supplier.name }}</h2>
        <ul class="subnav mb-0">
          <router-link
              v-for="vessel in getVesselsBySupplier(supplier.contactId)"
              :key="vessel.vesselId"
              :to="{name: 'BatchShow', params: {id: vessel.uniqueNumber}}">
            <li class="row">
              <label class="col-3">{{ vessel.uniqueNumber }}
                <span v-if="getVesselChilds(vessel.vesselId)"><br/>{{ getVesselChildsText(vessel) }}</span>
                <span v-if="parseInt(vessel.parent_vesselId) > 0"><br/>{{ parseNumber(vessel.weight) }} kg &rarr; {{ getVesselsParentText(vessel) }}</span>
              </label>
              <div class="col-2 text-right">{{ getVesselWeight(vessel) }}</div>
              <div class="col-3"></div>
            </li>
          </router-link>
        </ul>
        <div class="row mb-3">
          <div class="col-3 bold">Summe</div>
          <div class="col-2 bold text-right">{{ supplierTotal(supplier.contactId) }} kg</div>
        </div>
      </div>
    </div>
  </div>
  <app-btn-back class="pt-2"/>
</template>

<script>
export default {
  name: "StockPage",
  async created() {
    this.$store.state.vessel = []
    await this.$store.dispatch('getItemList', {entityName: 'vessel', method: 'dried'})
    await this.$store.dispatch('initItems', {entityName: 'supplier'})

    if (this.isIntern)
      await this.$store.dispatch('initItems', {entityName: 'regrater'})
  },
  computed: {
    isIntern() {
      return this.$store.getters.isIntern
    }
  },
  methods: {
    suppliers() {
      return this.$store.state.supplier
    },
    regraters() {
      return this.$store.state.regrater
      // return this.$store.state.batch.filter(batch => batch.washedWeight === null)
    },
    getVesselsByRegrater(contactId) {
      return this.$store.state.vessel.filter(vessel => vessel.regraterId == contactId)
    },
    regraterTotal(contactId, formatted) {
      const vessels = this.getVesselsByRegrater(contactId)
      let total = 0

      for (let idx in vessels) {
        total += parseFloat(vessels[idx]['weight'])
      }
      if (formatted === false)
        return total

      return this.parseNumber(total)
    },
    getVesselsBySupplier(contactId) {
      return this.$store.state.vessel.filter(vessel => vessel.supplierId == contactId)
    },
    supplierTotal(contactId, formatted) {
      // console.log('suptotal',this.$store.state.vessel)
      // console.log(this.$store.state.supplier)
      const vessels = this.getVesselsBySupplier(contactId)
      let total = 0

      for (let idx in vessels) {
        total += parseFloat(vessels[idx]['weight'])
      }
      if (formatted === false)
        return total

      return this.parseNumber(total)
    },
    formattedInt(number) {
      return parseInt(number).toLocaleString('de-DE')
    },
    getVesselWeight(vessel) {
      let weight = parseFloat(vessel.weight)

      if (parseInt(vessel.parent_vesselId) > 0)
        return ''

      const childs = this.getVesselChilds(vessel.vesselId)
      if (childs) {
        for (const child of childs) {
          weight += parseFloat(child.weight)
        }
      }

      return this.parseNumber(weight) + ' kg'
    },
    getVesselChilds(vesselId) {
      const childs = this.$store.state.vessel.filter(vessel => vessel.parent_vesselId == vesselId)

      if (childs.length > 0)
        return childs

      return null
    },
    getVesselChildsText(vessel) {
      let texts = [
        vessel.uniqueNumber + ' ' + parseFloat(vessel.weight) + ' kg'
      ]
      const childs = this.getVesselChilds(vessel.vesselId)

      for (const child of childs) {
        texts.push(child['uniqueNumber'] + ' ' + parseFloat(child.weight) + ' kg')
      }

      return texts.join(', ')
    },
    getVesselsParentText(vessel) {
      const parent = this.$store.state.vessel.filter(entry => entry.vesselId === vessel.parent_vesselId)

      if (parent.length)
        return parent[0]['uniqueNumber']

      return ''
    },

    parseNumber(number) {

      if (parseInt(number) != number)
        number = parseFloat(number)
      else
        number = parseInt(number)
      //return number
      return number.toLocaleString('de-DE')
    },
  }
}
</script>

<style scoped>
h2 {
  padding-top: 0.5rem;
}

.subnav li:after {
  margin-left: -16px;
}
</style>