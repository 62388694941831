<template>
  <h1>Einstellung</h1>
  <form @submit.prevent="save">
    <div class="form-row">
      <div class="col-8">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="name">{{ elementLabel }}</label>
          </div>
          <select v-if="elementType === 'b'" v-model="form.value" class="form-control" id="name">
            <option v-for="item in selectOptions" :key="item.opt" :value="item.opt">{{ item.label }}</option>
          </select>
          <input v-else v-model="form.value" class="form-control" id="name" placeholder="">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3 ">
        <button @click="closeForm" type="reset">Abbrechen</button>
      </div>
      <div class="col-5">
        <button type="submit">Speichern</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "SettingEditor",
  data() {
    return {
      form: {
        value: null,
      },
      selectOptions: [
        {
          opt:'true',
          label:'Ja',
        },
        {
          opt:'false',
          label:'Nein',
        }
      ]
    }
  },
  props: {
    elementId: {
      type: Number,
      default: null
    },
    elementLabel: {
      type: String,
      required: true
    },
    elementKey: {
      type: String,
      required: true
    },
    elementValue: {
      type: String,
      required: false
    },
    elementType: {
      type: String,
      default: 's'
    }
  },
  created() {
    this.form.value = this.elementValue
  },
  methods: {
    closeForm() {
      this.$emit('closeForm')
    },
    savedForm() {
      this.$emit('savedForm')
    },
    async save() {
      const response = await this.$store.dispatch('cruItem', {name: 'setting', id: this.elementId, data: this.form})
      // console.log('got response',response)
      if (response && response.success === true) {

        //TODO update setting in store
        // if (this.$props.elementKey === 'bondnote_pdf') {

        // }

        this.savedForm()
      }
    },
    loadData(entry) {
      this.form = entry
    },
    toggleValueType() {
      this.valueType = this.valueType === 'b';
    }
      
  }
}
</script>

<style scoped>

</style>