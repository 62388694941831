<template>
  <h1>Kontakt # {{ elementId }}</h1>
  <form @submit.prevent="save">
    <div class="form-row">
      <div class="col-8 col-md-4 col-lg-3 col-xl-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="name">Name</label>
          </div>
          <input v-model="form.name" class="form-control" id="name" placeholder="">
        </div>
      </div>
      <div v-if="elementId && (form.contactType === '0' || form.contactType === 0)" class="col-8 col-md-4 col-lg-3 col-xl-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="responsible">Verantwortlich</label>
          </div>
          <input v-model="form.responsible" class="form-control" id="responsible" placeholder="">
        </div>
      </div>
      <!--      <div class="col-8 col-md-4 col-lg-3 col-xl-2">-->
      <!--        <div class="input-group mb-3">-->
      <!--          <div class="input-group-prepend">-->
      <!--            <label class="input-group-text" for="firstname">Vorname</label>-->
      <!--          </div>-->
      <!--          <input v-model="form.firstname" class="form-control" id="firstname" placeholder="">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-8 col-md-4 col-lg-3 col-xl-2">-->
      <!--        <div class="input-group mb-3">-->
      <!--          <div class="input-group-prepend">-->
      <!--            <label class="input-group-text" for="firstname">Nachname</label>-->
      <!--          </div>-->
      <!--          <input v-model="form.lastname" class="form-control" id="lastname" placeholder="">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-8 col-md-4 col-lg-3 col-xl-3">-->
      <!--        <div class="input-group mb-3">-->
      <!--          <div class="input-group-prepend">-->
      <!--            <label class="input-group-text" for="company">Firma</label>-->
      <!--          </div>-->
      <!--          <input v-model="form.company" class="form-control" id="company" placeholder="">-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="form-row">
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="email">E-Mail</label>
          </div>
          <input v-model="form.email" class="form-control" id="email" placeholder="">
        </div>
      </div>
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="mobilePhone">Mobiltelefon</label>
          </div>
          <input v-model="form.mobilePhone" class="form-control" id="mobilePhone" placeholder="">
        </div>
      </div>
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="phone">Telefon</label>
          </div>
          <input v-model="form.phone" class="form-control" id="phone" placeholder="">
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-8 col-md-4 col-lg-3 col-xl-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="street">Straße</label>
          </div>
          <input v-model="form.street" class="form-control" id="street" placeholder="">
        </div>
      </div>
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="zip">PLZ</label>
          </div>
          <input v-model="form.zip" class="form-control" id="zip" placeholder="">
        </div>
      </div>
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="city">Ort</label>
          </div>
          <input v-model="form.city" class="form-control" id="city" placeholder="">
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="contactType">Typ</label>
          </div>
          <select
              v-model="form.contactType"
              class="custom-select" id="contactType">
            <option>Auswählen..</option>
            <option v-if="elementId && isOwner()" value="0">Inhaber</option>
            <option value="1">Lieferant</option>
            <option value="2">Aufkäufer</option>
          </select>
        </div>
      </div>
      <div v-if="form.contactType === '1' || form.contactType === 1" class="col-8 col-md-4 col-lg-3 col-xl-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="regraterId">Aufkäufer</label>
          </div>
          <select
              v-model="form.regraterId"
              class="custom-select" id="regraterId"
              required>
            <option>Auswählen..</option>
            <option
                v-for="regrater in regraters"
                :key="regrater.contactId"
                :value="regrater.contactId">{{ regrater.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="form.contactType === '1' || form.contactType === 1" class="form-row">
      <div class="col-8 col-md-4 col-lg-3 col-xl-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="fleetNumber">LW-Betriebsnummer</label>
          </div>
          <input v-model="form.fleetNumber" class="form-control" id="fleetNumber" placeholder="">
        </div>
      </div>
      <div class="col-8 col-md-4 col-lg-3 col-xl-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="supplier_qualityStandard">Qualitäts-Standard</label>
          </div>
          <select
              v-model="form.supplier_qualityStandard"
              class="custom-select" id="supplier_qualityStandard">
            <option>Auswählen..</option>
            <option
                v-for="qualityStandard in qualityStandards"
                :key="qualityStandard.id"
                :value="qualityStandard.id">{{ qualityStandard.name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="isBioWithUnion()" class="col-8 col-md-4 col-lg-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="city">Bio-Verband</label>
          </div>
          <input v-model="form.bio_union" class="form-control" id="bio_union" placeholder="">
        </div>
      </div>
    </div>

    <div class="form-row">
      <div v-if="isBioWithUnion() || isOwner()" class="col-8 col-md-4 col-lg-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="city">Bio-Kontrollstelle</label>
          </div>
          <input v-model="form.bio_control_authority" class="form-control" id="bio_control_authority" placeholder="">
        </div>
      </div>
    </div>

    <div v-if="form.contactType === '2' || form.contactType === 2">
      <h2>PitStory Zugang</h2>
      <div class="form-row">
        <div class="col-8 col-md-4 col-lg-3 col-xl-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_accessGranted">Aktiv</label>
            </div>
            <select
                v-model="form.regrater_accessGranted"
                class="custom-select" id="regrater_accessGranted">
              <option value="0">Nein</option>
              <option value="1">Ja</option>
            </select>
          </div>
        </div>
      </div>

      <h2>Artikel</h2>
      <div class="form-row">
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_convAT_articleName">Konv. AT Artikel</label>
            </div>
            <input v-model="form.regrater_convAT_articleName" class="form-control" id="regrater_convAT_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_convAT_articleNumber">Konv. AT Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_convAT_articleNumber" class="form-control" id="regrater_convAT_articleNumber"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioAT_articleName">Bio AT Artikel</label>
            </div>
            <input v-model="form.regrater_bioAT_articleName" class="form-control" id="regrater_bioAT_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioAT_articleNumber">Bio AT Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_bioAT_articleNumber" class="form-control" id="regrater_bioAT_articleNumber"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_convGGA_articleName">Konv. GGA Artikel</label>
            </div>
            <input v-model="form.regrater_convGGA_articleName" class="form-control" id="regrater_convGGA_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_convGGA_articleNumber">Konv. GGA Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_convGGA_articleNumber" class="form-control"
                   id="regrater_convGGA_articleNumber" placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioGGA_articleName">Bio GGA Artikel</label>
            </div>
            <input v-model="form.regrater_bioGGA_articleName" class="form-control" id="regrater_bioGGA_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioGGA_articleNumber">Bio GGA Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_bioGGA_articleNumber" class="form-control" id="regrater_bioGGA_articleNumber"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_convEU_articleName">Konv. EU Artikel</label>
            </div>
            <input v-model="form.regrater_convEU_articleName" class="form-control" id="regrater_convEU_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_convEU_articleNumber">Konv. EU Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_convEU_articleNumber" class="form-control" id="regrater_convEU_articleNumber"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioEU_articleName">Bio EU Artikel</label>
            </div>
            <input v-model="form.regrater_bioEU_articleName" class="form-control" id="regrater_bioEU_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioEU_articleNumber">Bio EU Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_bioEU_articleNumber" class="form-control" id="regrater_bioEU_articleNumber"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_convEurope_articleName">Konv. Europa Artikel</label>
            </div>
            <input v-model="form.regrater_convEurope_articleName" class="form-control"
                   id="regrater_convEurope_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_convEurope_articleNumber">Konv. Europa Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_convEurope_articleNumber" class="form-control"
                   id="regrater_convEurope_articleNumber"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioAT_articleName">Bio AT Verband Artikel</label>
            </div>
            <input v-model="form.regrater_bioATunion_articleName" class="form-control"
                   id="regrater_bioATunion_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioAT_articleNumber">Bio AT Verband Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_bioATunion_articleNumber" class="form-control"
                   id="regrater_bioATunion_articleNumber"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioGGA_articleName">Bio GGA Verband Artikel</label>
            </div>
            <input v-model="form.regrater_bioGGAunion_articleName" class="form-control"
                   id="regrater_bioGGAunion_articleName"
                   placeholder="">
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="regrater_bioGGA_articleNumber">Bio GGA Verband Artikel-Nr.</label>
            </div>
            <input v-model="form.regrater_bioGGAunion_articleNumber" class="form-control"
                   id="regrater_bioGGAunion_articleNumber"
                   placeholder="">
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <button @click="closeForm" type="reset">Abbrechen</button>
      </div>
      <div class="col-5">
        <button type="submit">Speichern</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "ContactEditor",
  data() {
    return {
      form: {
        contactId: 0,
        name: '',
        responsible: '',
        // firstname: '',
        // lastname: '',
        // company: '',
        contactType: 0,
        email: '',
        mobilePhone: '',
        phone: '',
        street: '',
        zip: '',
        city: '',
        supplier_qualityStandard: 0,
        regraterId: '',
        regrater_accessGranted: 0,
        regrater_convAT_articleName: '',
        regrater_convAT_articleNumber: '',
        regrater_bioAT_articleName: '',
        regrater_bioAT_articleNumber: '',
        regrater_convGGA_articleName: '',
        regrater_convGGA_articleNumber: '',
        regrater_bioGGA_articleName: '',
        regrater_bioGGA_articleNumber: '',
        regrater_convEU_articleName: '',
        regrater_convEU_articleNumber: '',
        regrater_bioEU_articleName: '',
        regrater_bioEU_articleNumber: '',
        regrater_convEurope_articleName: '',
        regrater_convEurope_articleNumber: '',
        regrater_bioATunion_articleName: '',
        regrater_bioATunion_articleNumber: '',
        regrater_bioGGAunion_articleName: '',
        regrater_bioGGAunion_articleNumber: '',
        bio_control_authority: '',
        bio_union: '',
        ama_surface_area: '',
        fleetNumber: ''
      }
    }
  },
  props: {
    elementId: {
      type: Number,
      default: null
    }
  },
  computed: {
    qualityStandards() {
      return this.$store.state.qualityStandards
    },
    regraters() {
      return this.$store.state.regrater
    }
  },
  methods: {
    closeForm() {
      this.$emit('closeForm')
    },
    async save() {
      const response = await this.$store.dispatch('cruItem', {name: 'contact', id: this.elementId, data: this.form})
      if (response && response.success === true) {
        this.closeForm()
      }
    },
    loadData(entry) {
      this.form = entry
    },
    isOwner() { console.log(this.form.contactType);
      return this.form.contactType === '0' || this.form.contactType === 0
    },
    isBioWithUnion() {
      switch (parseInt(this.form.supplier_qualityStandard)) {
        case 11:
        case 10:
          return true
        default:
          return false
      }
    }
  },
  created() {
    if (this.elementId) {
      this.$store.dispatch('getItem', {name: 'contact', id: this.elementId})
          .then(this.loadData.bind(this))
    }
    this.$store.dispatch('getItemList', {entityName: 'regrater'})
  }
}
</script>

<style scoped>

</style>