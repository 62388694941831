export default {
    setUser(state, {userId, contactName, userUid, userPermission, userRole, userToken, tokenExpiration, appSetting}) {
        state.userId = userId
        state.contactNames = contactName
        state.userUid = userUid
        state.userToken = userToken
        state.tokenExpiration = tokenExpiration
        state.userPermission = userPermission
        state.userRole = userRole
        state.appSetting = appSetting
    },
    setUserStorage(state, {userId, contactName, userUid, userPermission, userRole, userToken, tokenExpiration, appSetting}) {
        localStorage.setItem('userId', userId)
        localStorage.setItem('contactName', contactName)
        localStorage.setItem('userUid', userUid)
        localStorage.setItem('userToken', userToken)
        localStorage.setItem('tokenExpiration', tokenExpiration)
        localStorage.setItem('userPermission', userPermission)
        localStorage.setItem('userRole', userRole)
        localStorage.setItem('appSetting', JSON.stringify(appSetting))
    },
    unsetUser(state) {
        state.userId = null
        state.contactName = null
        state.userUid = null
        state.userToken = null
        state.tokenExpiration = null
        state.userPermission = null
        state.userRole = null
        state.appSetting = null
        localStorage.removeItem('userId')
        localStorage.removeItem('contactName')
        localStorage.removeItem('userUid')
        localStorage.removeItem('userToken')
        localStorage.removeItem('tokenExpiration')
        localStorage.removeItem('userPermission')
        localStorage.removeItem('userRole')
        localStorage.removeItem('appSetting')
    },
    setItemList(state, {entityName, list}) {
        state[entityName] = list ? list : []
    },
    setItemChild(state, {entityName, list, parentEntity, parentId}) {
        const index = state[parentEntity].findIndex(item => item[parentEntity + 'Id'] == parentId)
        if (index >= 0)
            state[parentEntity][index][entityName] = list ? list : []
    },
    cruItem(state, {name, id, data, parentEntity, parentId}) {
        let object;
        let index;
 // console.log('cruitem',name, id, data, parentEntity, parentId)
        if (parentEntity && parentId) {
            index = state[parentEntity].findIndex(item => item[parentEntity + 'Id'] == parentId)
            object = state[parentEntity][index][name]
            if (!object) {
                state[parentEntity][index][name] = []
            }
            object = state[parentEntity][index][name]
        } else {
            object = state[name]
            if (!object) {
                state[name] = []
            }
            object = state[name]
        }
 // console.log('obj',object,'idx',index)
        if (id) {
            index = object.findIndex(item => item[name + 'Id'] == id)
            object[index] = {
                ...object[index],
                ...data
            }
            // console.log('write into state', id, index, {
            //     ...object[index],
            //     ...data
            // })
        } else {
            object.push(data)
        }
    },
    setTitle(state, title) {
        state.title = title
    },
    setAuthId(state, id) {
        state.authId = id
    },
    setBatch(state, {batch}) {
        // console.log(batch)
        state.batch.push(batch)
    },
    updateBatch(state, {id, batch}) {
        // console.log(batch)
        const index = state.batch.findIndex(batch => batch.id === id)
        state.batch[index] = batch
    },
    setBag(state, {bag, batchId}) {
        const batchIndex = state.batch.findIndex(batch => batch.id === batchId)
        state.batch[batchIndex].vessel.push(bag)
    },
    setStockLocations(state, stockLocations){
        state.stockLocations= stockLocations;
    }
}