<template>
  <h1 v-show="!isIntern" class="mt-12 mt-1">Aufkäufer {{ getUserContactName }}</h1>
  <ul
      class="nav" :class="!isIntern && 'mt-0'">
    <li
        v-if="isIntern"
        @click="showQrReader" class="qr">
      QR-Code
      <img src="@/assets/images/icon-qr-code.svg"/>
    </li>
    <router-link
        v-for="navitem in navitems"
        :key="navitem.name"
        :to="{name: navitem.route}">
      <li v-show="isIntern && navitem.name === 'Verladen' || navitem.name !== 'Verladen'">{{ navitem.name }}</li>
    </router-link>
  </ul>
  <ul
      v-if="isAdmin"
      class="nav mt-4">
    <router-link
        v-for="navitem in adminNavitems"
        :key="navitem.name"
        :to="{name: navitem.route}">
      <li>{{ navitem.name }}</li>
    </router-link>
  </ul>

  <div class="row mt-3">
    <div class="col-4">
      <button @click="logout" type="reset">Abmelden</button>
    </div>
  </div>

  <app-qr-reader v-if="qr"/>
</template>

<script>
import AppQrReader from "@/components/AppQrReader";
export default {
  name: "PageHome",
  components: {
    AppQrReader
  },
  data() {
    return {
      qr: false
    }
  },
  computed: {
    navitems() {
      return this.$store.state.nav
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isIntern() {
      return this.$store.getters.isIntern
    },
    getUserContactName() {
      return this.$store.getters.contactName
    },
    adminNavitems() {
      return this.$store.state.navAdmin
    }
  },
  methods: {
    showQrReader() {
      this.qr = true;
    },
    async logout() {
      await this.$store.dispatch('signOut')
      this.$router.push({name: 'SignIn'})
    }
  }
}
</script>

<style scoped>

</style>