import {createApp} from 'vue'
import App from './App.vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
// import './registerServiceWorker'
import './assets/css/main.scss'
import store from '@/store'
import router from '@/router'
// import axios from 'axios'
// import VueAxios from 'vue-axios'
// import httpService from './plugins/httpService'

const psApp = createApp(App)
psApp.use(store)
psApp.use(router)
// psApp.use(VueAxios, axios)

// import base App-Components globally
const requireComponent = require.context('./components', false, /App[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    )
    psApp.component(
        componentName, componentConfig.default || componentConfig
    )
})

psApp.mount('#app')
