<template>
<!--  <form @submit.prevent="readQR">-->
<!--        <label for="customFile">-->
<!--          <button>QR Code</button>-->
<!--          <input v-show="false" @change="readQR" type="file" accept="image/*" capture="camera" id="customFile">-->
<!--        </label>-->
<!--    <div class="custom-file">-->
<!--      <input @change="readQR" type="file" accept="image/*" capture="camera" id="customFile" class="custom-file-input">-->
<!--      <label class="custom-file-label" for="customFile">QR-Code</label>-->
<!--    </div>-->
<!--  </form>-->
  <div class="full-popup">
    <p v-if="error" class="error">{{ error }}</p>
<!--    <p class="decode-result">Last result: <b>{{ result }}</b></p>-->

    <qrcode-stream @decode="onDecode" @init="onInit" />
  </div>
<!--  <qrcode-capture></qrcode-capture>-->
</template>

<script>
import { QrcodeStream } from 'qrcode-reader-vue3'
// import { QrcodeCapture } from 'qrcode-reader-vue3'

export default {
  name: "AppQrReader",
  components: {
    QrcodeStream,
    // QrcodeCapture
  },
  data() {
    return {
      // qrCode: ''
      result: '',
      error: ''
    }
  },
  props: {
    doEmit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // readQR(event) {
    //   if (event.target.files) {
    //     const file = event.target.files[0]
    //     this.$store.dispatch('readQR', {file})
    //   }
    // }
    onDecode (result) {
      this.result = result
      // console.log('decoded: '+ result)
      if (this.doEmit) {
        this.$emit('qrRead', {qrString: this.result})
      } else {
        this.$router.push({name: 'BatchShow', params: {id: this.result}})
      }
    },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
    }
  }
}
</script>

<style scoped>
form {
  margin-top: 3rem;
}
</style>