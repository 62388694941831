<template>
  <div class="row">
    <h1>Charge <span v-if="batchId" class="key heavy">{{ batch.uniqueNumber }}</span></h1>
    <ul class="output col-8 mb-0">
      <li><label class="col-2">Lieferant</label> {{ getSupplierName(batch.supplierId) }}</li>
      <li v-if="batch.qualityStandard"><label class="col-2">Standard</label> {{ batch.qualityStandard }}</li>
      <li v-if="batch.breed"><label class="col-2">Sorte</label> {{ batch.breed }}</li>
      <li><label class="col-2">Aufkäufer</label> {{ getRegrater(batch.regraterId) }}</li>
      <li v-if="batch.lane"><label class="col-2">Gasse</label> {{ batch.lane }}</li>
      <li v-if="parseInt(batch.washedWeight)>0"><label class="col-2">Nass</label> {{ parseNumber(batch.washedWeight) }} kg
      </li>
      <li v-if="batch.washedQuality"><label class="col-2">Qualität</label> {{ batch.washedQuality }}</li>
      <li v-if="batch.washedPicture1"><label class="col-2">Foto</label> <img :src="batch.washedPicture1"
                                                                             style="max-width: 60%;max-height: 300px"/>
      </li>
      <li v-if="batch.sampleNumber"><label class="col-2">Probe Nr</label> {{ batch.sampleNumber }}</li>
    </ul>
    <div class="w-100 mb-3">
      <button v-if="canWrite && !batch.driedWeight" @click="editBatch(batch)" class="edit">~</button>
      <div v-if="batchId" class="col-8 text-right">
        <api-pdf-link method="batchslip" :elementId="batch.uniqueId" class="float-right"/>
      </div>
    </div>
  </div>
  <div v-if="batch.driedWeight" class="row">
    <h2>Getrocknet</h2>
    <ul class="output col-8 mb-0">        
      <li v-if="batch.driedWeight"><label class="col-3">Trocken</label> {{ parseNumber(batch.driedWeight) }} kg</li>
      <li v-if="batch.driedMoisture > 0"><label class="col-3">Feuchte</label> {{ batch.driedMoisture }} %</li>
      <li v-if="batch.driedHlWeight > 0"><label class="col-3">HL-Gewicht</label> {{ batch.driedHlWeight }} kg</li>
    </ul>
  </div>
  <div v-if="nextStep === 'wet'"
       class="row">
    <bag-list :batch="batch" :bags="bags" :workflow-state="0" :can-write="canWrite"/>
  </div>
  <div v-if="nextStep === 'dry'"
       class="row">

    <div v-if="canWrite && (sentToSupplier || washedSentMail)" class="col-8 mt-3">
      <img class="opened" src="@/assets/images/icon-done.svg"/>
      Chargen-PDF an Lieferant gesendet
    </div>
    <div v-show="canWrite && sendToSupplier && !sentToSupplier" class="col-8">
      <button @click="sendEmail">Chargen-PDF an Lieferant senden</button>
    </div>
    <div v-if="sentToSupplierError" class="col-8 mt-3">
      <img class="opened" src="@/assets/images/icon-err.svg"/>
      {{ sentToSupplierError }}
    </div>

    <bag-list :batch="batch" :bags="bags" :workflow-state="1" :can-write="canWrite" :regraters="regraters"/>
    <!--    <h2>Trocknen</h2>-->
    <!--    <form @submit.prevent="save" class="col-8">-->
    <!--&lt;!&ndash;      <input name="formName" value="dry" type="hidden"/>&ndash;&gt;-->
    <!--      <div class="input-group mb-3">-->
    <!--        <div class="input-group-prepend">-->
    <!--          <label class="input-group-text" for="dryWeight">Trockengewicht</label>-->
    <!--        </div>-->
    <!--        <input v-model="dryWeight" type="number" class="form-control form-control-lg"-->
    <!--               id="dryWeight" placeholder="">-->
    <!--        <div class="input-group-append">-->
    <!--          <span class="input-group-text">kg</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <button type="submit">Speichern</button>-->
    <!--    </form>-->
  </div>
  <div v-else-if="nextStep === 'stock' || nextStep === 'loading'"
       class="row">
    <bag-list :batch="batch" :bags="bags" :workflow-state="2" :can-write="canWrite"/>
    <h2 v-if="isIntern && showBondnotePdf">Warenbegleitschein</h2>
    <h2 v-else>Lagerschein</h2>
    <!-- PDF Lagerliste -->
    <div class="col-2">{{ batch.uniqueNumber }}</div> 
    <div class="col-6 text-right">
      <api-pdf-link v-if="isIntern && showBondnotePdf" method="bondnote" :elementId="batch.uniqueId" label="PDF" class="float-right"/>
      <api-pdf-link v-else method="stockslip" :elementId="batch.uniqueId" label="PDF" class="float-right"/>
    </div>
    <!-- div v-if="showDryingNotePdf" class="col-5 mt-2">Trocknungsbegleitschein</div>
    <div v-if="showDryingNotePdf" class="col-3 mt-2 text-right">
      <api-pdf-link method="dryingnote" :elementId="batch.uniqueId" label="PDF" class="float-right"/>
    </div -->

    <!--PDF Warenbegleitschein-->
<!--    <div v-if="showBondnotePdf" class="d-flex w-100 mt-2">
      <div class="col-4">Warenbegleitschein</div>
      <div class="col-4 text-right">
      </div>
    </div>-->
    <!--    <div class="col-3 text-right">PDF versenden</div>-->
    <div v-if="canWrite && (sentToSupplier || driedSentMail)" class="col-8 mt-3">
      <img class="opened" src="@/assets/images/icon-done.svg"/>
      {{ stockslipLabel }} an Lieferant gesendet
    </div>
    <div v-show="canWrite && sendToSupplier && !sentToSupplier" class="col-8">
      <button @click="sendEmail">{{ stockslipLabel }} an Lieferant senden</button>
    </div>
    <div v-if="sentToSupplierError" class="col-8 mt-3">
      <img class="opened" src="@/assets/images/icon-err.svg"/>
      {{ sentToSupplierError }}
    </div>
  </div>
  <div v-if="canWrite && nextStep === 'loading' && batch.driedWeight"
       class="row">
    <div class="col-8">
      <router-link :to="{name:'LoadingShow', params: {id:0}}">
        <button>Verladen</button>
      </router-link>
    </div>
  </div>
  <app-btn-back class="pt-2" :router-link="backLink"/>
  <popup-batch-editor v-if="batchEditorOpened" :element="batchElement" :suppliers="suppliers"
                      :regraters="regraters" :quality-standards="qualityStandards"
                      @abortDialog="abortBatchEdit" @confirmDialog="saveBatchEdit"></popup-batch-editor>
</template>

<script>
import BagList from "@/components/BagList";
import ApiPdfLink from "@/components/ApiPdfLink";
import PopupBatchEditor from "@/components/PopupBatchEditor";
import dayjs from "dayjs";

export default {
  name: "PageBatchShow",
  components: {PopupBatchEditor, BagList, ApiPdfLink},
  data() {
    return {
      batchId: 0,
      activeBatch: {},
      lastStep: '',
      step: '',
      dryWeight: null,
      backLink: '',
      sendToSupplier: false,
      sentToSupplier: false,
      sentToSupplierError: '',
      batchElement: null,
      batchEditorOpened: false,
      washedSentMail: false,
      driedSentMail: false,
      stockslipLabel: "Lagerschein"
    }
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  async created() {
    this.setBatchId(this.id)  

    // console.log('------------------created')
    await this.$store.dispatch('getItemList', {entityName: 'batch'})
    await this.$store.dispatch('getItemsByParent', {
      entityName: 'vessel',
      parentEntity: 'batch',
      parentId: this.batchId
    })
    await this.$store.dispatch('getItemList', {entityName: 'supplier'})
    await this.$store.dispatch('getItemList', {entityName: 'regrater'})
    await this.$store.dispatch('initItems', {entityName: 'setting'})
    // console.log('------------------created done')

    const batch = this.getBatch()
    if (batch) {
      const supplier = this.getSupplier(batch.supplierId)

      if (parseInt(batch['washedSentMail']))
        this.washedSentMail = true
      if (parseInt(batch['driedSentMail']))
        this.driedSentMail = true
      if (supplier['email'])
        this.sendToSupplier = true
    }
    if (this.showBondnotePdf)
      this.stockslipLabel = "Warenbegleitschein"
  },
  async beforeMount() {
    // console.log('------------------beforeMount')
    // console.log('------------------beforeMount end')
  },
  async mounted() {
    // console.log('------------------mounted')
    // load batch washed image
    const batchData = await this.$store.dispatch('getItem', {name: 'batch', id: this.batchId})
    const batch = this.getBatch()

    if (batchData && batchData['washedPicture1'])
      batch['washedPicture1'] = batchData['washedPicture1']
    // console.log('------------------mounted end')
  },
  computed: {
    suppliers() {
      return this.$store.state.supplier
    },
    regraters() {
      return this.$store.state.regrater
    },
    qualityStandards() {
      return this.$store.state.qualityStandards
    },
    dryersAmount() {
      return this.$store.getters.dryersAmount
    },
    canWrite() {
      return this.$store.getters.canWrite
    },
    isIntern() {
      return this.$store.getters.isIntern
    },
    showBatchTime() {
      return this.$store.getters.batchTime
    },
    showBatchLane() {
      return this.$store.getters.batchLane
    },
    showBatchSampleNumber() {
      return this.$store.getters.batchSampleNumber
    },
    showBondnotePdf() {
      //return false
      return this.$store.getters.bondnotePdf
    },
    showDryingNotePdf() {
      return this.$store.getters.dryingNotePdf
    },
    getIncomingLocationNames() {
      return this.$store.getters.incomingLocationNames
    },
    batch() {
      let batch = this.getBatch()
      // console.log('*** computed batch', batch)
      if (batch) {
        if (batch.qualityStandard > 0) {
          const object = this.$store.state.qualityStandards.find(quality => quality.id == batch.qualityStandard)
          if (object) {
            batch.qualityStandardId = batch.qualityStandard
            batch.qualityStandard = object.name
          }
        }
      } else
        batch = {}

      return batch
    },
    bags() {
      const batch = this.getBatch()
      if (batch && batch.vessel) return batch.vessel
      else return []
    },
    nextStep() {
      const batch = this.getBatch()

      if (batch) {
        return this.getNextStep(batch)
      }
      return ''
    }
  },
  methods: {
    // batch() {
    //   return this.getBatch()
    // },
    // batch() {
    //   let batch = this.getBatch()
    //   // console.log('*** methods batch', batch)
    //   if (batch) {
    //     if (batch.qualityStandard > 0) {
    //       const object = this.$store.state.qualityStandards.find(quality => quality.id === batch.qualityStandard)
    //       batch.qualityStandard = object.name
    //     }
    //   } else
    //     batch = {}
    //
    //   return batch
    // },
    getBatch() {
      // console.log('getbatch', this.id, this.$store.state.batch)
      return this.$store.state.batch.find(batch => batch.batchId == this.batchId)
    },
    getNextStep(batch) {
      if (batch) {
        if (batch.washedWeight === null) {
          this.step = 'wet'
          this.backLink = 'IncomingPage'
        } else if (!batch.driedWeight) {
          this.step = 'dry'
          this.backLink = 'DryingPage'
        } else if (!batch.vessel) {
          this.step = 'stock'
          this.backLink = 'DryingPage'
        } else if (!batch.loaded) {
          this.step = 'loading'
          this.backLink = 'StockPage'
        }

        if (this.step !== this.lastStep) {
          this.sentToSupplier = false
          this.lastStep = this.step
        }

        return this.step
      }
    },
    getSupplier(id) {
      if (!id || !this.$store.state.supplier) return ''
      const item = this.$store.state.supplier.find(item => item.contactId === id)
      //TODO if item missing: request from server by two credentials to validate
      if (!item) return null
      return item
    },
    getSupplierName(id) {
      const item = this.getSupplier(id)

      if (!item) return '–'
      return item.name
    },
    getRegrater(id) {
      if (!id || !this.$store.state.regrater) return '–'
      const item = this.$store.state.regrater.find(item => item.contactId === id)
      if (!item) return '–'
      return item.name
    },
    save() {
      let batch = {}

      switch (this.step) {
        case 'wet':
          document.write('step wet')
          // store vessel with workflow state washed and wet weight
          break
        case 'dry':
          batch = {
            dryWeight: this.dryWeight
          }
          this.$store.dispatch('updateBatch', {id: this.batchId, batch})
          this.dryWeight = null;
          this.step = 'stock'
          break
      }
    },
    setBatchId(id) {
      const pos = id.indexOf('.')
      if (pos >= 0)
        id = id.substring(0, pos)
      if (id.indexOf('CH') === 0)
        id = id.substring(2)

      this.batchId = id
    },
    async sendEmail() {
      // console.log('sendMail')
      const response = await this.$store.dispatch('sendEmail', {
        type: this.step === 'dry' ? 'batchslip' : 'stockslip',
        uid: this.batch.uniqueId
      })
      this.sentToSupplier = false

      if (response.success) {
        if (this.step === 'dry')
          this.washedSentMail = true
        else
          this.driedSentMail = true

        this.sentToSupplier = true
      } else {
        if (this.step === 'dry')
          this.washedSentMail = false
        else
          this.driedSentMail = false

        this.sentToSupplier = false

        this.sentToSupplierError = response.err || 'Es ist ein Fehler aufgetreten.'
      }
      // console.log(response)
    },
    // batch popup editor
    editBatch(batch) {
      this.batchElement = batch
      this.batchEditorOpened = true
    },
    abortBatchEdit() {
      this.batchElement = null
      this.batchEditorOpened = false
    },
    async saveBatchEdit({id, supplierId, regraterId, qualityStandard, breed, batchDate, batchTime, incomingLocationId, sampleNumber}) {
      const data = {
        supplierId, regraterId, qualityStandard, breed, batchDate
      }
      if (this.showBatchTime)
        data.batchTime = batchTime
      if (this.getIncomingLocationNames)
        data.incomingLocationId = incomingLocationId
      if (this.showBatchSampleNumber)
        data.sampleNumber = sampleNumber

      const response = await this.$store.dispatch('cruItem',
          {name: 'batch', id, data: data})

      if (response && response.success === true) {
        const object = this.$store.state.qualityStandards.find(quality => quality.id == qualityStandard)
        const customParseFormat = require('dayjs/plugin/customParseFormat')
        dayjs.extend(customParseFormat)

        this.batchElement = {
          ...this.batchElement,
          supplierId,
          regraterId,
          qualityStandardId: qualityStandard,
          breed,
          qualityStandard: object.name,
          batchDate: dayjs(batchDate, 'DD.MM.YYYY', true).format('YYYY-MM-DD'),
          batchTime,
        }
        this.abortBatchEdit()
        // console.log(this.getNextStep(this.getBatch()))
        if (this.getNextStep(this.getBatch()) === 'dry') {
          // reload vessels
          this.$store.dispatch('getItemsByParent', {
            entityName: 'vessel', parentEntity: 'batch',
            parentId: this.batch.batchId, method: 'dried'
          })
        }
      }
    },

    parseNumber(number) {
      if (parseInt(number) != number)
        number = parseFloat(number)
      else
        number = parseInt(number)

      return number.toLocaleString('de-DE')
    },
  }
}
</script>

<style scoped>
.opened {
  margin-top: -4px
}

button.edit {
  float: left;
}
</style>