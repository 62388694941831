export default {
    pageTitle: state => state.title,
    authUser: state => state.authId != null,
    userId: state => state.userId,
    contactName: state => state.contactNames,
    userUid: state => state.userUid,
    userToken: state => state.userToken,
    isAuthenticated: state => !!state.userToken,
    isAdmin: state => state.userPermission > 0 && state.userPermission <= 2,
    canWrite: state => state.userPermission > 0 && state.userPermission <= 3,
    isIntern: state => state.userRole <= 1,
    // settings
    dryersAmount: state => state.appSetting ? parseInt(state.appSetting.dryersAmount) : 1,
    printerEmail: state => state.appSetting ? state.appSetting.printerEmail : '',
    bondnotePdf: state => state.appSetting ? state.appSetting.bondnotePdf === "true" : false,
    incomingBruttoTara: state => state.appSetting ? state.appSetting.incomingBruttoTara === "true" : false,
    incomingLocationNames: state => state.appSetting.incomingLocationNames,
    dryingNotePdf: state => state.appSetting ? state.appSetting.dryingNotePdf === "true" : false,
    batchSampleNumber: state => state.appSetting ? state.appSetting.batchSampleNumber === "true" : false,
    batchLane: state => state.appSetting ? state.appSetting.batchLane === "true" : false,
    batchTime: state => state.appSetting ? state.appSetting.batchTime === "true" : false,
    washingTime: state => state.appSetting ? state.appSetting.washingTime === "true" : false,
    dryingTemperature: state => state.appSetting ? state.appSetting.dryingTemperature === "true" : false,
    dryingHeatMeter: state => state.appSetting ? state.appSetting.dryingHeatMeter === "true" : false,
    dryingStartTime: state => state.appSetting ? state.appSetting.dryingStartTime === "true" : false,
    dryingStartMoisture: state => state.appSetting ? state.appSetting.dryingStartMoisture === "true" : false,
    dryingMoundHeight: state => state.appSetting ? state.appSetting.dryingMoundHeight === "true" : false,
    vesselStockLocation: state => state.appSetting ? state.appSetting.vesselStockLocation === "true" : false,
    dryers: state => state.appSetting.dryers,
    // config
    appApiBase: state => state.appApiBase,
    appInstance: state => state.appInstance,
}