<template>
  <div v-if="activeLoading.regraterId">
    <h1>Lieferung <span class="key heavy">L{{ activeLoading.loadingId }}</span></h1>
    <ul class="output col-8 mb-0">
      <li><label class="col-3">Aufkäufer</label> {{ getRegrater(activeLoading.regraterId) }}</li>
      <li v-if="activeLoading.deliverySlipNumber"><label class="col-3">Lieferschein</label> {{ activeLoading.deliverySlipNumber }}</li>
      <li v-if="activeLoading.licensePlateNumber"><label class="col-3">Kennzeichen</label> {{ activeLoading.licensePlateNumber }}</li>
      <li v-if="activeLoading.licensePlateTrailer"><label class="col-3">Anhänger</label> {{ activeLoading.licensePlateTrailer }}</li>
    </ul>
    <div class="w-100 mb-3">
      <button @click="editLoading()" class="edit">~</button>
    </div>
    <div v-if="activeLoading.vessel.length">
      <h2>Ladeliste</h2>
      <ul class="list w-100">
        <li v-for="(vessel, idx) in getLoadedVessels()"
            :key="vessel.vesselId" class="row">
          <button v-if="!finishedLoading()" @click="openDialog(
              { element: 'der Big Bag',nbr: vessel.uniqueNumber, weight: vessel.weight, uid: vessel.uniqueId})"
                  class="del col-1">-</button>
          <div class="col-1">{{ idx+1 }}</div>
          <label :class="'col-'+(finishedLoading() ? 5 : 3)">{{ vessel.uniqueNumber }} {{ vessel.supplier }}</label>
          <div class="col-2 text-right">{{ getVesselWeight(vessel) }}</div>
<!--          <span v-if="getVesselChilds(vessel.vesselId)"><br/>{{ getVesselChildsText(vessel) }}</span>-->
<!--          <div v-if="finishedLoading()" class="col-3 text-right">-->
<!--            <api-pdf-link method="bigbagslip" :elementId="vessel.uniqueId" class="float-right"/>-->
<!--          </div>-->
        </li>
      </ul>
      <div class="row mb-3">
        <div :class="'col-'+(finishedLoading() ? 6 : 5)" class="bold">Summe</div>
        <div class="col-2 bold text-right" :class="!finishedLoading() && 'pr-0'">{{ totalWeight() }} kg</div>
      </div>
      <div v-if="activeLoading.vessel.length || finishedLoading()" class="row mb-3">
        <div class="col-8">
          <api-pdf-link method="loadingslip" label="Lieferschein PDF" :elementId="activeLoading.uniqueId" class="float-right"/>
        </div>
      </div>
    </div>
    <div v-if="!finishedLoading()">
      <h2>Big Bags</h2>
      <ul class="nav" style="margin:0 0 0.5rem 0">
        <li @click="showQrReader" class="qr">
          QR-Code
          <img src="@/assets/images/icon-qr-code.svg"/>
        </li>
      </ul>
      <form @submit.prevent="saveBagLoading" class="col-8">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="bag">Big Bag</label>
          </div>
          <select
              v-model="bag"
              class="custom-select custom-select-lg" id="bag">
            <option>Auswählen..</option>
            <option
                v-for="bag in this.loadableVessels"
                :key="bag.vesselId"
                :value="bag.vesselId">
              {{ getBigBagLabel(bag) }}
            </option>
          </select>
        </div>
        <button type="submit" class="mt-1">Geladen</button>
      </form>
      <button @click="finishLoading">Abschluss</button>
    </div>
  </div>
  <div v-else-if="!finishedLoading()" class="row">
    <h1>Verladen</h1>
    <form @submit.prevent="save" class="col-8">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="regraterId">Aufkäufer</label>
        </div>
        <select
            v-model="regraterId"
            class="custom-select custom-select-lg" id="regraterId" required>
          <option>Auswählen..</option>
          <option
              v-for="regrater in regraters"
              :key="regrater.contactId"
              :value="regrater.contactId"
          >{{ regrater.name }}</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="deliverySlipNumber">Lieferschein</label>
        </div>
        <input v-model="deliverySlipNumber" type="text" class="form-control form-control-lg" id="deliverySlipNumber"
               placeholder="">
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="truck">Kennzeichen</label>
        </div>
        <input v-model="truck" type="text" class="form-control form-control-lg" id="truck" placeholder="">
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="trailer">Kennzeichen Anhänger</label>
        </div>
        <input v-model="trailer" type="text" class="form-control form-control-lg" id="trailer" placeholder="">
      </div>
      <button type="submit">Starten</button>
    </form>
  </div>
  <app-btn-back class="pt-2" routerLink="LoadingPage"/>
  <app-qr-reader v-if="qr" :doEmit="true" @qrRead="qrRead"/>
  <app-modal-dialog v-if="modalOpened" @abortDialog="abortDialog" @confirmDialog="confirmDialog">
    <h1>Entfernen bestätigen</h1>
    <div class="w-100">{{ modalText }}</div>
    <div class="w-100"><strong>{{ modalElement }}</strong></div>
  </app-modal-dialog>
  <popup-loading-editor v-if="loadingEditorOpened" :element="activeLoading" :regraters="regraters"
                        @abortDialog="abortLoadingEdit" @confirmDialog="saveLoadingEdit"></popup-loading-editor>
</template>

<script>
import ApiPdfLink from "@/components/ApiPdfLink";
import AppModalDialog from "@/components/AppModalDialog";
import PopupLoadingEditor from "@/components/PopupLoadingEditor";

export default {
  name: "LoadingShow",
  components: {AppModalDialog,ApiPdfLink,PopupLoadingEditor},
  data() {
    return {
      loadingId: null,
      regraterId: null,
      loadableVessels: null,
      activeLoading: {
        vessel: []
      },
      deliverySlipNumber: '',
      truck: '',
      trailer: '',
      bag: '',
      loadingDone: false,
      storedBags: [],
      qr: false,
      modalOpened: false,
      modalText: '',
      modalElement: '',
      modalUid: '',
      loadingEditorOpened: false,
    }
  },
  props: {
    id: {
      required: false,
      type: String
    }
  },
  async created() {
    if (this.id) {
      this.loadingId = this.id
      await this.getActiveLoading()
    }
    await this.$store.dispatch('getItemList', {entityName: 'regrater'})
    // this.$store.state.vessel = []
    await this.getLoadableVessels()
  },
  computed: {
    regraters() {
      return this.$store.state.regrater
    },
  },
  methods: {
    // vessels() {
    //   return this.$store.state.vessel
    // },
    parseNumber(number) {
      console.log(parseInt(number),number)
      if (parseInt(number) != number)
        return number
      else
        return parseInt(number)
    },
    editLoading() {
      this.loadingEditorOpened = true
    },
    abortLoadingEdit() {
      this.loadingEditorOpened = false
    },
    totalWeight() {
      const vessels = this.activeLoading.vessel
      let total = 0

      for (let idx in vessels) {
        total += parseFloat(vessels[idx]['weight'])
      }
      return this.parseNumber(total)
    },
    async save() {
      const data = {
        regraterId: this.regraterId,
        deliverySlipNumber: this.deliverySlipNumber,
        licensePlateNumber: this.truck,
        licensePlateTrailer: this.trailer,
      }
      const response = await this.$store.dispatch('cruItem', {name: 'loading', data})
      // console.log('saved',response.id)
      this.loadingId = response.id
      this.activeLoading = {
        ...this.activeLoading,
        loadingId: response.id,
        regraterId: this.regraterId,
        deliverySlipNumber: this.deliverySlipNumber,
        licensePlateNumber: this.truck,
        licensePlateTrailer: this.trailer,
        label: this.deliverySlipNumber || this.truck
      }
    },
    async saveLoadingEdit({regraterId, deliverySlipNumber,licensePlateNumber,licensePlateTrailer}) {
      const data = {
        regraterId,deliverySlipNumber,licensePlateNumber,licensePlateTrailer
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'loading', id: this.loadingId, data: data})
      const changedRegrater = regraterId != this.activeLoading.regraterId

      this.activeLoading = {
        ...this.activeLoading,
        regraterId,
        deliverySlipNumber,
        licensePlateNumber,
        licensePlateTrailer,
        label: deliverySlipNumber || licensePlateNumber
      }

      if (response && response.success === true) {
        this.abortLoadingEdit()
        if (changedRegrater) await this.getLoadableVessels()
      }
    },
    async saveBagLoading() {
      const data = {
        loadingId: this.loadingId
      }

      await this.$store.dispatch('cruItem', {name: 'vessel', id: this.bag, data})
      await this.getActiveLoading()

      // const bagIndex = this.storedBags.findIndex(bag => bag.id === this.bag)
      // this.storedBags.splice(bagIndex, 1)
      await this.getLoadableVessels()
    },
    async finishLoading() {
      const data = {
        finishedLoading: 1
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'loading', id: this.loadingId, data})

      if (response && response.success) {
        this.activeLoading.finishedLoading = true
      }
      // this.$props.batch.bagged = true
    },
    finishedLoading() {
      // console.log('###',this.activeLoading.finishedLoading)
      if (this.activeLoading.finishedLoading == '0')
        return false

      return this.activeLoading.finishedLoading
    },
    async getActiveLoading() {
      this.activeLoading = await this.$store.dispatch('getItem', {name: 'loading', id: this.loadingId})
      this.activeLoading.label = this.activeLoading.deliverySlipNumber || this.activeLoading.truck
    },
    async getLoadableVessels() {
      //this.loadableVessels = await this.$store.dispatch('getItemList', {entityName: 'vessel', method: 'loadable', returnData: true})
      this.loadableVessels = await this.$store.dispatch('getItemsByParent',
          {entityName: 'vessel', method: 'loadable', returnData: true, regraterId: this.activeLoading.regraterId})
    },
    showQrReader() {
      this.qr = true
    },
    qrRead(params) {
      this.setBagId(params.qrString)
      this.saveBagLoading()
      this.qr = false
    },
    formattedInt(number) {
      return parseInt(number).toLocaleString('de-DE')
    },
    setBagId(id) {
      const vessel = this.loadableVessels.filter((vessel) => vessel.uniqueNumber === id)[0];

      /*const pos = id.indexOf('.')
      if (pos >= 0)
        id = id.substring(pos+1)
      if (id.indexOf('BB') === 0)
        id = id.substring(2)*/

      this.bag = vessel.vesselId
    },
    openDialog({element, nbr, weight, uid}) {
      this.modalText = 'Soll '+ element +' aus der Ladeliste entfernt werden?'
      this.modalElement = nbr + ' mit '+ this.parseNumber(weight) +' kg'
      this.modalOpened = true
      this.modalUid = uid
    },
    abortDialog() {
      this.modalOpened = false
    },
    async confirmDialog() {
      this.modalOpened = false
      this.bag = ''
      await this.$store.dispatch('delItem', {name:'loading', uid:this.modalUid})
      await this.getActiveLoading()
      await this.getLoadableVessels()
    },
    getLoadedVessels() {
      return this.activeLoading.vessel.filter(vessel => parseInt(vessel.parent_vesselId) === 0 || vessel.parent_vesselId == null)
    },
    getVesselWeight(vessel) {
      let weight = parseFloat(vessel.weight)

      if (parseFloat(vessel.parent_vesselId) > 0)
        return ''

      const childs = this.getVesselChilds(vessel.vesselId)
      if (childs) {
        for (const child of childs) {
          weight += parseFloat(child.weight)
        }
      }

      return this.parseNumber(weight) + ' kg'
    },
    getVesselChilds(vesselId) {
      const childs = this.activeLoading.vessel.filter(vessel => vessel.parent_vesselId == vesselId)

      if (childs.length > 0)
        return childs

      return null
    },
    getVesselChildsText(vessel) {
      let texts = [
        vessel.uniqueNumber + ' ' + parseInt(vessel.weight) + ' kg'
      ]
      const childs = this.getVesselChilds(vessel.vesselId)

      for (const child of childs) {
        texts.push(child['uniqueNumber'] + ' ' + this.parseNumber(child.weight) + ' kg')
      }

      return texts.join(', ')
    },
    getRegrater(id) {
      if (!id || !this.$store.state.regrater) return '–'
      const item = this.$store.state.regrater.find(item => item.contactId === id)
      if (!item) return '–'
      return item.name
    },
    getBigBagLabel(bag) {
      let label = bag.uniqueNumber +' '+ this.parseNumber(bag.weight) +' kg | '

      if (bag.stockLocation) label += bag.stockLocation +' | '

      label += bag.supplier

      if (!this.activeLoading.regraterId) label += ' › '+ bag.regrater

      return label
    },
  }
}
</script>

<style scoped>

</style>