<template>
  <h2>Charge</h2>
  <form @submit.prevent="save" class="row ml-0 mr-0">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="supplierId">Lieferant</label>
      </div>
      <select
          v-model="supplierId"
          @change="supplierChanged"
          class="custom-select custom-select-lg" id="supplierId"
          required>
        <option>Auswählen..</option>
        <option
            v-for="supplier in suppliers"
            :key="supplier.contactId"
            :value="supplier.contactId">{{ supplier.name }}, {{ supplier.city }}</option>
      </select>
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="qualityStandard">Standard</label>
      </div>
      <select
          v-model="qualityStandard"
          class="custom-select custom-select-lg" id="qualityStandard"
      >
        <option>Auswählen..</option>
        <option
            v-for="qualityStandard in qualityStandards"
            :key="qualityStandard.id"
            :value="qualityStandard.id">{{ qualityStandard.name }}</option>
      </select>
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="breed">Sorte</label>
      </div>
      <input v-model="breed" class="form-control form-control-lg" id="breed" placeholder="">
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="regraterId">Aufkäufer</label>
      </div>
      <select
          v-model="regraterId"
          class="custom-select custom-select-lg" id="regraterId">
        <option>Auswählen..</option>
        <option
            v-for="regrater in regraters"
            :key="regrater.contactId"
            :value="regrater.contactId">{{ regrater.name }}</option>
      </select>
    </div>
    <div :class="'input-group mb-3'+ (showBatchTime ? ' col-4 w55 pl-0' : '' )">
      <div class="input-group-prepend">
        <label class="input-group-text" for="batchDate" :style="showBatchTime && 'min-width:auto'">{{ !showBatchTime ? 'Datum' : 'D' }}</label>
      </div>
      <input v-model="batchDate" class="form-control form-control-lg"
             id="batchDate" placeholder="TT.MM.JJJJ" pattern="\d{1,2}.\d{1,2}.\d{4}">
    </div>
    <div v-if="showBatchTime" class="input-group col-4 w45 pr-0 mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="batchTime" style="min-width:auto">Z</label>
      </div>
      <input v-model="batchTime" class="form-control form-control-lg"
             id="batchTime" placeholder="HH:mm">
    </div>
    <div v-if="showBatchLane" class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="lane">Gasse</label>
      </div>
      <input v-model="lane" class="form-control form-control-lg" id="lane" placeholder="">
    </div>
    <div v-if="getIncomingLocationNames" class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="incomingLocationId">Annahme</label>
      </div>
      <select
          v-model="incomingLocationId"
          class="custom-select custom-select-lg" id="incomingLocationId">
        <option>Auswählen..</option>
        <option
            v-for="(location,id) in getIncomingLocationNames"
            :key="id"
            :value="id">{{ location }}
        </option>
      </select>
    </div>
    <div v-if="dryersAmount > 1" class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="dryerId">Trockner</label>
      </div>
      <select v-if="dryers"
              v-model="dryerId"
              class="custom-select custom-select-lg" id="dryerId">
        <option
            v-for="(item,index) in dryers"
            :key="index"
            :value="index">{{ item }}</option>
      </select>
      <select v-else
          v-model="dryerId"
          class="custom-select custom-select-lg" id="dryerId"
      >
        <option
            v-for="index in dryersAmount"
            :key="index"
            :value="index">Trockner {{ index }}</option>
      </select>
    </div>
<!--    <div class="input-group mb-3">-->
<!--      <div class="input-group-prepend">-->
<!--        <label class="input-group-text" for="exampleFormControlInput2">Nassgewicht</label>-->
<!--      </div>-->
<!--      <input v-model="newBatchAmount" type="number" class="form-control form-control-lg" id="exampleFormControlInput2" placeholder="">-->
<!--      <div class="input-group-append">-->
<!--        <span class="input-group-text">kg</span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <input type="file" accept="image/*" capture="camera" /><br><br>-->
    <button type="submit">Hinzufügen</button>
  </form>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "BatchEditor",
  data() {
    return {
      supplierId: '',
      regraterId: '',
      qualityStandard: 0,
      breed: '',
      batchDate: dayjs().format('DD.MM.YYYY'),
      batchTime: dayjs().format('HH:mm'),
      incomingLocationId: null,
      dryerId: null
      // newBatchAmount: '',
    }
  },
  created() {
    this.$store.dispatch('getItemList', {entityName:'supplier'})
    this.$store.dispatch('getItemList', {entityName:'regrater'})
  },
  computed: {
    suppliers() {
      return this.$store.state.supplier
    },
    regraters() {
      return this.$store.state.regrater
    },
    qualityStandards() {
      return this.$store.state.qualityStandards
    },
    dryersAmount() {
      return this.$store.getters.dryersAmount
    },
    dryers() {
      return this.$store.getters.dryers
    },
    getIncomingLocationNames() {
      return this.$store.getters.incomingLocationNames
    },
    showBatchTime() {
      return this.$store.getters.batchTime
    },
  },
  methods: {
    supplierChanged() {
      const supplier = this.$store.state.supplier.find(item => item.contactId === this.supplierId)
      this.qualityStandard = supplier.supplier_qualityStandard
      this.regraterId = supplier.regraterId
    },
    async save() {
      const batch = {
        supplierId: this.supplierId,
        regraterId: this.regraterId,
        breed: this.breed,
        qualityStandard: this.qualityStandard,
        dryerId: this.dryerId,
        batchDate: this.batchDate,
        bags: [],
        // wetWeight: this.newBatchAmount,
      }
      if (this.showBatchTime)
        batch.batchTime = this.batchTime
      if (this.getIncomingLocationNames)
        batch.incomingLocationId = this.incomingLocationId

      const response = await this.$store.dispatch('cruItem', {name: 'batch', data: batch})
      if (response && response.success === true) {
        this.openNewBatch(response.id)
      }
      // this.$emit('save', {batch})
      // this.batches.push(batch)
      // this.newBatchAmount = ''

      // this.$store.dispatch('createBatch', batch).then(this.openNewBatch.bind(this))
    },
    openNewBatch(id) {
      this.$router.push({name: 'BatchShow', params: { id }})

      /*
      // clear and close form
      this.supplierId = ''
      this.regraterId = ''
      this.newBatchAmount = ''
      this.edit = false
       */
    }
  }
}
</script>

<style scoped>
.input-group-prepend .input-group-text {
  min-width: 96px;
}
.w55 {
  flex: 0 0 55%;
  max-width: 55%;
}
.w45 {
  flex: 0 0 45%;
  max-width: 45%;
}
</style>