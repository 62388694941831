<template>
  <div class="btn-back" @click="goBack">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38"><defs><clipPath id="b"><rect width="38" height="38"/></clipPath></defs><g id="a" clip-path="url(#b)"><g transform="translate(-16 -17)"><circle cx="19" cy="19" r="19" transform="translate(16 17)" fill="#dee2e1"/><g transform="translate(44.938 43.625) rotate(180)"><g transform="translate(8.25 0.375)"><path d="M11,14.5l7.955-7L11,.5" transform="translate(-11 -0.5)" fill="none" stroke="#242229" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></g></g></g></svg>
  </div>
</template>

<script>
export default {
  name: "AppBtnBack",
  props: {
    routerLink: {
      type: String,
      required: false,
      default: 'Home'
    }
  },
  methods: {
    goBack() {
      if (this.routerLink)
        this.$router.push({name: this.routerLink})
      else
        this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>